import React, { useMemo } from 'react';
import classNames from 'classnames';

import './CodeConfirmation.styles.scss';
import useCodeConfirmation from './hooks/useCodeConfirmation';

type CodeConfirmationProps = {
  wrongCode?: boolean;
  onFinalCell?: (code: number) => void;
};
const CodeConfirmation = (props: CodeConfirmationProps) => {
  const { wrongCode, onFinalCell } = props;
  const classes = classNames('hhsa-code-confirmation');

  const { otp, maskedOtp, inputRefs, handleChange, handleKeyDown } =
    useCodeConfirmation(onFinalCell);

  const areAllFieldsFilled = useMemo(
    () => otp.every((field) => field !== ''),
    [otp],
  );

  return (
    <div className={classes}>
      {otp.map((_: any, index: number) => (
        <input
          key={index}
          autoFocus={index === 0}
          ref={(el) => (inputRefs.current[index] = el)}
          className={classNames('hhsa-code-confirmation__single-code', {
            'hhsa-code-confirmation__single-code--error':
              wrongCode && areAllFieldsFilled,
          })}
          value={maskedOtp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
        />
      ))}
    </div>
  );
};

export default CodeConfirmation;
