import React from 'react';
import { Navigate } from 'react-router-dom';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import CheckYourEmail from './pages/CheckYourEmail';

export default [
  {
    path: 'login',
    element: Login,
  },
  {
    path: 'forgot-password',
    element: ForgotPassword,
  },
  {
    path: 'check-your-email',
    element: CheckYourEmail,
  },
  {
    path: '',
    element: <Navigate to="/" replace />,
  },
] as Array<EnhancedRouteProps>;
