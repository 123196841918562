import React, { useCallback, useMemo, useRef, useState } from 'react';
import Table from '../../components/Table';
import useLabels from './hooks/useLabels';
import { useTranslation } from 'react-i18next';
import usePaginatedItems from '../hooks/usePaginatedItems';
import api from 'api';
import { InventoryLocationsSearchBy, locationSearchByOptions } from 'types';
import useQueryParams from 'hooks/useQueryParams';
import { InventoryType } from 'api/inventoryLocations';
import Modal, { ModalRef } from 'components/Modal';
import InventoryLocationsForm from './components/InventoryLocationsForm';
import { InventoryLocations } from 'models/Inventory';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import utils from 'utils';
import confirm from 'modules/confirm';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';

const InventoryLocationsPage = () => {
  const labels = useLabels();
  const [binNo, setBinNo] = useState<number>();

  const { t } = useTranslation();

  const modalRef = useRef<ModalRef>();
  const queryClient = useQueryClient();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: InventoryLocationsSearchBy;
  }>({ searchBy: 'storeNo' });

  const { items, isFetching, onRefresh, totalItems, totalPages } =
    usePaginatedItems({
      queryKey: 'locations',
      makeRequest: api.inventoryLocations.getInventoryLocations,
      searchParam: searchBy,
      params: {
        limit,
        page,
        [searchBy]: !!search && search?.length >= 2 ? search : undefined,
      },
    });

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(locationSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  const renderContentArray = useCallback(
    (data: InventoryType[]) => {
      return data?.map(({ locationName, type, ...rest }) => ({
        locationName,
        type: t(type),
        ...rest,
      }));
    },
    [t],
  );

  const addAssociate = useCallback(
    async (values: InventoryLocations) => {
      try {
        await api.inventoryLocations.addInventoryLocation(values);
        toast.success(t('InventoryLocations.successAddingLocation'));
        modalRef.current.close();
        setBinNo(null);
        queryClient.invalidateQueries({
          queryKey: ['locations'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const updateAssociate = useCallback(
    async (values: Partial<InventoryLocations>, id: number) => {
      try {
        await api.inventoryLocations.editInventoryLocation(values, id);
        toast.success(t('InventoryLocations.successUpdatingLocation'));
        modalRef.current.close();
        setBinNo(null);
        queryClient.invalidateQueries({
          queryKey: ['locations'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const onSubmit = useCallback(
    async (values: InventoryLocations, id?: number) => {
      values.storeNo = +values.storeNo;
      values.binNo = +values.binNo;
      values.maxShelfCapacity = +values.maxShelfCapacity;

      if (id) {
        updateAssociate(values, id);
      } else {
        addAssociate(values);
      }
    },
    [addAssociate, updateAssociate],
  );

  const deleteLocation = useCallback(
    async (binNo: string) => {
      try {
        await api.inventoryLocations.deleteLocation(binNo);
        toast.success(t('successDeletingAssociate'));
        onRefresh();
        queryClient.invalidateQueries({
          queryKey: ['associates'],
        });
      } catch (error) {
        toast.error(t('General.error'));
      }
    },
    [onRefresh, queryClient, t],
  );

  const handleDeletelocation = useCallback(
    async (values: Partial<InventoryLocations>) => {
      const confirmDeletion = confirm({
        title: t('InventoryLocations.deleteLocationTitle'),
        confirmBtnText: t('Delete'),
        content: t('InventoryLocations.deleteLocation', {
          binNo: values?.binNo,
        }),
      });

      if (await confirmDeletion) {
        deleteLocation(String(values?.binNo));
      }
    },
    [t, deleteLocation],
  );
  return (
    <>
      <Table
        className="manage-users__table"
        fetching={isFetching}
        title={t('InventoryLocations.title')}
        labels={labels}
        elementProps={labels.map(({ key }) => key)}
        contentArray={renderContentArray(items)}
        buttonText={t('InventoryLocations.add')}
        onEdit={(el) => {
          setBinNo(el.binNo);
          modalRef.current.open();
        }}
        onDelete={(values) => handleDeletelocation(values)}
        onAddButtonClick={() => modalRef.current.open()}
        withActionButtons
        totalItems={totalItems}
        totalPages={totalPages}
        limit={limit}
        searchable
        searchBy={searchBy}
        searchByOptions={searchByOptions}
        searchPlaceholder={t('InventoryLocations.searchPlaceholder')}
        onSearchByChange={handleSearchByChange}
      />
      <Modal
        focusableElements="div"
        className="manage-inventory-locations__modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-inventory-locations"
        onClose={() => setBinNo(null)}
        onX={() => {
          setBinNo(null);
          modalRef.current.close();
        }}
        title={
          binNo ? t('InventoryLocations.edit') : t('InventoryLocations.add')
        }
      >
        <InventoryLocationsForm
          onSubmit={(values) => onSubmit(values, binNo)}
          handleCancel={() => {
            modalRef.current.close();
          }}
          binNo={binNo}
        />
      </Modal>
    </>
  );
};

export default InventoryLocationsPage;
