import fileUrls from 'constants/fileUrls';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ManageStoresFormType } from 'types';

const useTemplateFile = (formType?: ManageStoresFormType) => {
  const { t } = useTranslation();

  return useMemo(() => {
    switch (formType) {
      case 'productUpload':
        return {
          url: fileUrls.productUploadTemplate,
          message: t('productDownloadTemplate'),
        };
      case 'upcMapping':
        return {
          url: fileUrls.upcUploadTemplate,
          message: t('upcDownloadTemplate'),
        };
      default:
        return;
    }
  }, [formType, t]);
};

export default useTemplateFile;
