import httpClient from 'api/httpClient';
import {
  Pagination,
  RequestParams,
  Searchable,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';

class SearchStoreProduct implements Searchable {
  keys() {
    return ['skuNo', 'storeNo'] as const;
  }
}
export type StoreInventoryProduct = {
  productId: string;
  lastInventoryUpdateDate?: Date;
  availableToSell: number;
  notAvailableToSell: number;
  lastReceivedDate?: Date;
  totalOnOrder: number;
  orderArrivalEstDate?: Date;
  totalStoreCapacity: number;
  shelfReplenishmentTypeId: number;
  shelfReplenishmentValue: number;
  storeReplenishmentTypeId: number;
  storeReplenishmentValue: number;
  maxShelfCount: number;
  currentShelfCount: number;
  minShelfCount: number;
  quantitySold: number;
  onHand: number;
  storeProduct?: {
    storeNo: number;
    skuNo: number;
  };
} & SearchStoreProduct & { id: string };

const getStoreProductInventory = (
  params: RequestParams<StoreInventoryProduct>,
): Promise<Pagination<StoreInventoryProduct>> =>
  httpClient
    .get('store-inventory', { params })
    .then((response) => response.data);

export default { getStoreProductInventory };
