import classNames from 'classnames';
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Tabs.styles.scss';

type TabsProps = {
  tabs: { path: string; label: string }[];
};

const Tabs = (props: TabsProps) => {
  const { tabs } = props;
  const location = useLocation();

  const { t } = useTranslation();

  return (
    <div className="store-tabs">
      <div className="store-tabs__list">
        {tabs.map((tab) => (
          <Link
            key={tab.path}
            to={tab.path}
            className={classNames('store-tabs__list__item', {
              'store-tabs__list__item--active': location.pathname === tab.path,
            })}
          >
            {t(tab.label)}
          </Link>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default Tabs;
