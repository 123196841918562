import StoreProducts from '../StoreProducts';
import StoreSales from '../StoreSales';
import RestockListPage from '../../../RestockList';
import { ComplexRoute } from 'router/components/Routes/Routes';

export default [
  {
    path: 'products',
    element: StoreProducts,
  },
  {
    path: 'sales',
    element: StoreSales,
  },
  {
    path: 'restock-list',
    element: RestockListPage,
  },
] as Array<ComplexRoute>;
