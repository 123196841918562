import React, { useCallback, useContext, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import api from 'api';
import { AxiosError } from 'axios';
import credentialsService from 'services/credentialsService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import { ForgotPasswordReqBody, LoginReqBody } from 'models/AuthBody';

import './Auth.styles.scss';

export type OutletContext = {
  login: (values: LoginReqBody) => Promise<void>;
  forgotPassword: (values: any) => Promise<void>;
  verifyCode: (code: number, email: string) => Promise<void>;
  isAuthInProgress: boolean;
  isForgotPasswordInProgress: boolean;
};

const Auth: React.FC = () => {
  const { setCurrentUser, getUser } = useContext(CurrentUserContext);

  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [isForgotPasswordInProgress, setIsForgotPasswordInProgress] =
    useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleLoginError = useCallback(
    (error: AxiosError) => {
      const { status } = error?.response || {};

      let errorMessage;

      switch (status) {
        case 404:
          errorMessage = t('Login.userNotFoundError');
          break;

        case 401:
          errorMessage = t('Login.invalid');
          break;

        default:
          errorMessage = t('General.error');

          break;
      }

      toast.error(errorMessage);
    },
    [t],
  );

  const login = useCallback(
    async (values: LoginReqBody) => {
      setIsAuthInProgress(true);
      const { associateId, password } = values;

      try {
        const {
          data: { token, refreshToken, user },
        } = await api.auth.login({
          associateId,
          password,
        });

        setCurrentUser(user);
        if (user.hasTmpPassword) {
          credentialsService.saveTmpBody({ token, refreshToken });
        } else {
          credentialsService.saveAuthBody({ token, refreshToken });
        }

        if (user.hasTmpPassword) {
          navigate('../reset-password', { state: { forgotPassword: false } });
        } else {
          await getUser();

          navigate('/');
        }
      } catch (error) {
        const e = error as AxiosError;
        handleLoginError(e);
      } finally {
        setIsAuthInProgress(false);
      }
    },
    [getUser, handleLoginError, navigate, setCurrentUser],
  );

  const forgotPassword = useCallback(
    async (values: ForgotPasswordReqBody) => {
      setIsForgotPasswordInProgress(true);
      try {
        await api.auth.forgotPassword(values.email);
      } catch (error) {
        toast.error(t('General.error'));
        throw error;
      } finally {
        setIsForgotPasswordInProgress(false);
      }
    },
    [t],
  );

  const verifyCode = useCallback(
    async (code: number, email: string) => {
      try {
        const {
          data: { token, refreshToken, user },
        } = await api.auth.verifyCode(code, email);
        setCurrentUser(user);

        credentialsService.saveTmpBody({ token, refreshToken });
      } catch (error) {
        toast.error(t('General.error'));
        throw error;
      }
    },
    [setCurrentUser, t],
  );
  return (
    <main className="hhsa-main">
      <Outlet
        context={{
          login,
          forgotPassword,
          isAuthInProgress,
          verifyCode,
          isForgotPasswordInProgress,
        }}
      />
    </main>
  );
};

export default Auth;

export const useAuth = () => useOutletContext<OutletContext>();
