import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { TextArea } from 'ncoded-component-library';

import './TextareaField.styles.scss';

type FieldTextareaProps = FieldRenderProps<string, HTMLElement>;

const TextareaField: React.FC<FieldTextareaProps> = (props) => {
  const { className, meta, label, input, rows = 3, ...rest } = props;

  const { error, touched } = meta;
  const hasError = error && touched;

  const classes = classNames('field-textarea', className);

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <TextArea rows={rows} {...input} {...rest} />
      {hasError && <small>{error}</small>}
    </div>
  );
};

export default TextareaField;
