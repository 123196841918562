import React from 'react';

export type IconProps = React.SVGProps<SVGSVGElement>;

const GoRightIcon: React.FC<IconProps> = (props) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="black" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.327452 0.410826C0.652889 0.0853888 1.18053 0.0853888 1.50596 0.410826L6.50596 5.41083C6.8314 5.73626 6.8314 6.2639 6.50596 6.58934L1.50596 11.5893C1.18053 11.9148 0.652889 11.9148 0.327452 11.5893C0.00201478 11.2639 0.00201478 10.7363 0.327452 10.4108L4.7382 6.00008L0.327452 1.58934C0.00201478 1.2639 0.00201478 0.736263 0.327452 0.410826Z"
    />
  </svg>
);

export default GoRightIcon;
