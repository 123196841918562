import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useAssociate from '../ManageUsers/components/ManageUsersForm/hooks/useAssociate';
import { Loader } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/Avatar';
import { useInfinitePaginatedItems } from '../hooks/useInfinitePaginatedItems';
import api from 'api';
import { activityColors, activityTypeOptions } from 'types';
import utils from 'utils';
import Filters from '../../components/Table/components/Filters';
import {
  TableFilter,
  TimeFilterValue,
} from '../../components/Table/Table.component';
import useQueryParams from 'hooks/useQueryParams';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import useChartData from './hooks/useChartData';
import { SelectedFilters } from '../../components/Table/components/Filters/Filters.component';
import NoSearchMatch from '../../components/Table/components/NoSearchMatch';

import './Associate.styles.scss';
import './Associate.styles.responsive.scss';

ChartJS.register(ArcElement, Tooltip);

type AssociateFilterTypes = 'types' | 'startDate' | 'endDate';

const Associate = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const containerRef = useRef(null);

  const { associate, loading: loadingAssociate } = useAssociate(+id);

  const {
    resetQueryParams,
    setQueryParam,
    params: { types, startDate, endDate, timeFilter },
  } = useQueryParams<{
    page: string;
    types: string;
    timeFilter: string;
    startDate: string;
    endDate: string;
  }>();

  const {
    items,
    hasNextPage,
    fetchNextPage,
    isFetching: loadingActivities,
  } = useInfinitePaginatedItems({
    queryKey: 'activityLog',
    makeRequest: api.analytics.getActivity,
    params: {
      limit: '100',
      page: '1',
    },
    additionalParams: {
      userId: id,
      types: types?.split(', '),
      startDate,
      endDate,
    },
  });

  const { pieData, chartOptions } = useChartData({
    userId: +id,
  });

  const handleResetFilters = useCallback(() => {
    resetQueryParams();
  }, [resetQueryParams]);

  const handleApplyFilters = useCallback(
    (filters: SelectedFilters) => {
      Object.keys(filters).map((filter) =>
        setQueryParam(filter as AssociateFilterTypes, filters[filter]),
      );
      setQueryParam('page', 1);
    },
    [setQueryParam],
  );

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const handleScrollToBottom = () => {
      if (!hasNextPage || loadingActivities) return;

      fetchNextPage();
    };

    const handleScroll = () => {
      if (
        container.scrollHeight - container.scrollTop ===
        container.clientHeight
      ) {
        handleScrollToBottom();
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [hasNextPage, fetchNextPage, loadingActivities]);

  const filterParams: TableFilter[] = useMemo(() => {
    const activityOptions = Object.values(activityTypeOptions).map(
      ({ value, label }) => ({
        label: t(`${value}`),
        value: label,
      }),
    );

    return [
      {
        type: 'checkbox',
        name: 'types',
        innerLabel: t('activityType'),
        selectValue: types,
        selectOptions: activityOptions,
      },
    ];
  }, [t, types]);

  const timeFilterParams: TimeFilterValue[] = useMemo(
    () => [
      { name: 'timeFilter', value: timeFilter },
      { name: 'startDate', value: startDate },
      { name: 'endDate', value: endDate },
    ],
    [endDate, startDate, timeFilter],
  );

  return (
    <section className="associate">
      {loadingAssociate ? (
        <Loader color="#007cb9" inline />
      ) : (
        <>
          <article className="associate__info">
            <Avatar
              withInfo={false}
              className="associate__avatar"
              img={associate?.profileImageUrl}
            />

            <dl>
              <div className="associate__info__item">
                <dt>{t('name')}</dt>
                <dd>{associate?.name}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('General.associateId')}</dt>
                <dd>{associate?.associateId}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('General.storeNo')}</dt>
                <dd>{associate?.storeNo}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('position')}</dt>
                <dd>{associate?.position}</dd>
              </div>
              <div className="associate__info__item">
                <dt>{t('moveInventoryStyle')}</dt>
                <dd>{associate?.inventoryScreenType}</dd>
              </div>
            </dl>
          </article>

          <article className="associate__activities">
            <header className="associate__activities__header">
              <label>{t('activityLog')}</label>
              <Filters
                onApplyFilters={handleApplyFilters}
                tableFilterParams={filterParams}
                buttonText={t('filters')}
                onResetFilters={handleResetFilters}
                timeFilterParams={timeFilterParams}
                hasTimeFilter
              />
            </header>

            <div className="associate__activities__main" ref={containerRef}>
              {items && (
                <section className="associate__activities__log">
                  <div className="associate__activity">
                    <div>{t('activityType')}</div>
                    <div>{t('skuNo')}</div>
                    <div>{t('updatedAt')}</div>
                  </div>
                  {!loadingActivities && items.length === 0 ? (
                    <NoSearchMatch
                      className="associate__no-match"
                      promptMessage={t('changeFilters')}
                    />
                  ) : (
                    items.map((item) => (
                      <div key={item?.id} className="associate__activity">
                        <div>
                          <div
                            className={`activity-type ${
                              activityColors[item?.type]
                            }`}
                          >
                            {t(`${item?.type?.replace(/\s/g, '')}`)}
                          </div>
                        </div>
                        <div>{item?.skuNo || '-'}</div>
                        <div>{utils.formatISODate(item?.updatedAt)}</div>
                      </div>
                    ))
                  )}
                  {loadingActivities && <Loader color="#007cb9" inline />}
                </section>
              )}

              <section className="associate__charts">
                {pieData && items.length !== 0 && (
                  <Pie options={chartOptions} data={pieData} />
                )}
              </section>
            </div>
          </article>
        </>
      )}
    </section>
  );
};

export default Associate;
