import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table';
import usePaginatedItems from '../hooks/usePaginatedItems';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useLabels from './hooks/useLabels';
import { SortDirection } from 'types';
import ActivityExport from './components/ActivityExport';
import useActivityLogFilters from './hooks/useActivityLogFilters';
import useContentArray from './hooks/useContentArray';
import { SelectedFilters } from '../../components/Table/components/Filters/Filters.component';
import { TimeFilterValue } from '../../components/Table/Table.component';

import './ActivityLog.styles.scss';
import './ActivityLog.styles.responsive.scss';

type AdditionalParams =
  | 'startDate'
  | 'types'
  | 'position'
  | 'storeNo'
  | 'userId';

const ActivityLog: React.FC = () => {
  const [sortOrder, setSortOrder] = useState<SortDirection>('ASC');

  const { t } = useTranslation();

  const labels = useLabels();

  const {
    resetQueryParams,
    removeQueryParam,
    setQueryParam,
    params: {
      page,
      limit,
      types,
      userId,
      position,
      storeNo,
      startDate,
      endDate,
      sortBy,
      sortDir,
      timeFilter,
    },
  } = useQueryParams<{
    page: string;
    limit: string;
    timeFilter: string;
    types: string;
    userId: string;
    position: string;
    storeNo: string;
    startDate: string;
    endDate: string;
    sortBy: string;
    sortDir: SortDirection;
  }>({ limit: '100' });

  const activeFilterParams = useMemo(
    () => ({
      startDate,
      types: types?.split(', '),
      position,
      storeNo,
      userId,
    }),
    [position, startDate, storeNo, types, userId],
  );

  const { items, isFetching, totalPages, totalItems } = usePaginatedItems({
    queryKey: 'activity-log',
    makeRequest: api.analytics.getActivity,
    refetchOnMount: 'always',
    params: {
      limit,
      page,
    },
    additionalParams: { ...activeFilterParams, endDate, sortBy, sortDir },
  });

  const { activityFilterParamas } = useActivityLogFilters({
    types,
    position,
    userId,
    storeNo,
  });

  const renderContentArray = useContentArray();

  const handleSortClick = useCallback(
    (key: string) => {
      if (key === sortBy) {
        setSortOrder((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
      }
      setQueryParam('sortBy', key);
      setQueryParam('sortDir', sortOrder);
    },
    [setQueryParam, sortBy, sortOrder],
  );

  const handleResetFilters = useCallback(() => {
    resetQueryParams();
  }, [resetQueryParams]);

  const handleApplyFilters = useCallback(
    (filters: SelectedFilters) => {
      Object.keys(filters).map((filter) =>
        setQueryParam(filter as any, filters[filter]),
      );

      setQueryParam('page', 1);
    },
    [setQueryParam],
  );

  const handleRemoveFilter = useCallback(
    (param: any) => {
      removeQueryParam(param);
      if (param === 'startDate') {
        removeQueryParam('endDate');
        removeQueryParam('timeFilter');
      }
    },
    [removeQueryParam],
  );

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const labelsSort = useMemo(
    () =>
      labels?.map((label) =>
        label?.key === sortBy ? { ...label, sortDir } : label,
      ),
    [labels, sortBy, sortDir],
  );

  const activeFilters = useMemo(
    () =>
      Object.keys(activeFilterParams)?.filter(
        (val) => activeFilterParams[val as AdditionalParams],
      ),
    [activeFilterParams],
  );

  const timeFilterParams: TimeFilterValue[] = useMemo(
    () => [
      { name: 'timeFilter', value: timeFilter },
      { name: 'startDate', value: startDate },
      { name: 'endDate', value: endDate },
    ],
    [endDate, startDate, timeFilter],
  );

  return (
    <Table
      className="activity-log-table"
      rowClassName="activity-log-table-row"
      fetching={isFetching}
      activeFilters={activeFilters}
      onRemoveFilter={handleRemoveFilter}
      title={t('activityLog')}
      totalItems={totalItems}
      labels={labelsSort}
      elementProps={labels.map(({ key }) => key)}
      contentArray={renderContentArray(items)}
      totalPages={totalPages}
      limit={limit}
      hasTimeFilter
      timeFilterParams={timeFilterParams}
      tableFilterParams={activityFilterParamas}
      onApplyFilters={handleApplyFilters}
      headerButtons={<ActivityExport isFetching={isFetching} />}
      onResetFilters={handleResetFilters}
      onHeaderClick={handleSortClick}
    />
  );
};

export default ActivityLog;
