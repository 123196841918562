import React, { useCallback, useMemo } from 'react';
import usePaginatedItems from '../../../hooks/usePaginatedItems';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import { salesSearchByOptions, StoreSaleSearchBy } from 'types';
import { useParams } from 'react-router-dom';
import Table from 'router/subrouters/Dashboard/components/Table';
import { useTranslation } from 'react-i18next';
import useLabels from './hooks/useLabels';
import { StoreSale } from 'models/Sale';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

const StoreSales = () => {
  const { t } = useTranslation();
  const labels = useLabels();

  const { storeNo } = useParams();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: StoreSaleSearchBy;
  }>({ searchBy: 'name' });

  const { items, isFetching, totalPages, totalItems } = usePaginatedItems({
    queryKey: 'store-sales',
    makeRequest: api.sales.getStoreSales,
    searchParam: searchBy,
    params: {
      limit,
      page,
      [searchBy]: !!search && search.length >= 2 ? search : undefined,
      storeNo,
    },
  });

  const searchByOptions = useMemo(
    () =>
      Object.values(salesSearchByOptions).map(({ value, label }) => ({
        label: t(label),
        value,
      })),
    [t],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  const calculatePrice = useCallback((quantity: number, price: number) => {
    return (quantity * price).toFixed(2);
  }, []);
  const renderContentArray = useCallback(
    (data: StoreSale[]) =>
      data?.map(
        ({
          quantity,
          price,
          productCatalog: { name, imageUrlEn },
          ...rest
        }) => ({
          imageUrl: imageUrlEn,
          quantity,
          price,
          totalPrice: calculatePrice(quantity, price),
          ...rest,
        }),
      ),
    [calculatePrice],
  );

  return (
    <Table
      title={t('Sales')}
      labels={labels}
      contentArray={renderContentArray(items)}
      totalItems={totalItems}
      fetching={isFetching}
      totalPages={totalPages}
      searchBy={searchBy}
      searchable
      searchPlaceholder={t('SaleSearch')}
      searchByOptions={searchByOptions}
      onSearchByChange={handleSearchByChange}
      elementProps={labels.map(({ key }) => key)}
    />
  );
};

export default StoreSales;
