import React from 'react';
import classNames from 'classnames';

import './Progress.styles.scss';

type ProgressProps = {
  className?: string;
  value?: number;
  max?: number;
};

const Progress: React.FC<ProgressProps> = (props) => {
  const { className, ...rest } = props;

  const classes = classNames('hhsa-progress', className);

  return <progress className={classes} {...rest} />;
};

export default Progress;
