import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const labels = useMemo(
    () => [
      { label: '', key: 'imageUrl' },
      { label: t('id'), key: 'id' },
      { label: t('skuNo'), key: 'skuNo' },
      { label: t('General.associateId'), key: 'createUserId' },
      { label: t('quantity'), key: 'quantity' },
      { label: t('itemPrice'), key: 'price' },
      { label: t('saleValue'), key: 'totalPrice' },
    ],
    [t],
  );
  return labels;
};
