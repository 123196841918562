import InputField from 'components/InputField';
import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TextareaField from 'components/TextareaField';
import { Button } from 'ncoded-component-library';
import { TranslationItem } from 'models/Translation';

import './ManageTranslationsForm.styles.scss';

type ManageTranslationFormProps = {
  selectedItem: TranslationItem | null;
  language?: string;
  onSubmit?: (values: { key: string; value: string }) => void;
  handleCancel?: () => void;
};
const ManageTranslationForm = (props: ManageTranslationFormProps) => {
  const { selectedItem, onSubmit, handleCancel } = props;

  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      key: selectedItem?.key,
      value: selectedItem?.translation,
    }),
    [selectedItem?.key, selectedItem?.translation],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedItem ? initialValues : {}}
      render={(formRenderProps) => {
        const { handleSubmit } = formRenderProps;
        return (
          <form onSubmit={handleSubmit} className="hhsa-manage-translations">
            <InputField type="text" name="key" label={t('key')} />
            <Field
              component={TextareaField}
              type="text"
              name="value"
              label={t('value')}
              style={{ height: 'fitContent' }}
            />

            <span className="hhsa-manage-translations__info-message">
              {t('Translations.editInfoMessage')}
            </span>
            <div className="hhsa-manage-translations__actions">
              <Button
                type="button"
                variant="outline"
                onClick={handleCancel}
                className="hhsa-manage-translations__actions__link"
              >
                <span>{t('cancel')}</span>
              </Button>

              <Button
                type="submit"
                variant="solid"
                className="hhsa-manage-translations__actions__submit"
              >
                {selectedItem ? t('editTranslation') : t('addTranslation')}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default ManageTranslationForm;
