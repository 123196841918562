import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { activityTypeOptions, positionOptions } from 'types';
import { TableFilter } from 'router/subrouters/Dashboard/components/Table/Table.component';
import api from 'api';
import usePaginatedItems from '../../hooks/usePaginatedItems';

type ActivityLogFilters = {
  types: string;
  position: string;
  userId: string;
  storeNo: string;
};

export default (props: ActivityLogFilters) => {
  const { types, position, userId, storeNo } = props;

  const { t } = useTranslation();

  const { items: associates } = usePaginatedItems({
    queryKey: 'associates',
    makeRequest: api.associates.getAssociates,
    params: {
      limit: '250',
    },
  });

  const { items: stores } = usePaginatedItems({
    queryKey: 'stores',
    makeRequest: api.stores.getStores,
    params: {
      limit: '250',
    },
  });

  const activityOptions = useMemo(
    () =>
      Object.values(activityTypeOptions).map(({ value, label }) => ({
        label: t(`${value}`),
        value: label,
      })),
    [t],
  );

  const positions = useMemo(
    () =>
      Object.values(positionOptions).map(({ value }) => ({
        label: t(`${value}`),
        value,
      })),
    [t],
  );

  const userOptions = useMemo(() => {
    if (!associates) return [];

    return Object.values(associates).map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [associates]);

  const storeOptions = useMemo(() => {
    if (!stores) return [];

    return Object.values(stores).map(({ storeNo, name }) => ({
      label: name,
      value: `${storeNo}`,
    }));
  }, [stores]);

  const activityFilterParamas: TableFilter[] = [
    {
      type: 'checkbox',
      name: 'types',
      innerLabel: t('activityType'),
      selectValue: types,
      selectOptions: activityOptions,
    },
    {
      type: 'radio',
      name: 'position',
      innerLabel: t('associatePosition'),
      selectValue: position,
      selectOptions: positions,
    },
    {
      type: 'select',
      name: 'userId',
      innerLabel: t('General.associateName'),
      selectValue: userId,
      selectOptions: userOptions,
    },
    {
      type: 'select',
      name: 'storeNo',
      innerLabel: t('store'),
      selectValue: storeNo,
      selectOptions: storeOptions,
    },
  ];

  return { activityFilterParamas };
};
