import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import ErrorBoundary, { ErrorFallback } from 'components/ErrorBoundary';
import CurrentUserProvider from 'providers/CurrentUser';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeTranslations } from './i18n/config';
import './i18n/config';

const baseURL = import.meta.env.VITE_ASSET_URL as string;

const queryClient = new QueryClient();

function App() {
  const [isTranslationsLoaded, setIsTranslationsLoaded] = useState(false);

  useEffect(() => {
    initializeTranslations().then(() => {
      setIsTranslationsLoaded(true);
    });
  }, []);

  if (!isTranslationsLoaded) return;

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <BrowserRouter basename={baseURL}>
        <QueryClientProvider client={queryClient}>
          <CurrentUserProvider>
            <Routes routes={routes} />
          </CurrentUserProvider>
        </QueryClientProvider>
      </BrowserRouter>
      <ToastContainer />
    </ErrorBoundary>
  );
}

export default App;
