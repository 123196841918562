import React, { useCallback } from 'react';
import { Button, Loader, Select } from 'ncoded-component-library';
import classNames from 'classnames';
import Row, { RowProps } from '../Row/Row.component';
import { useTranslation } from 'react-i18next';
import Filter from 'components/Filter';
import Pagination from 'components/Pagination';
import useScrollBottom from 'hooks/useScrollBottom';
import { SortDirection, limitOptions, minLimitOptionValue } from 'types';
import utils from 'utils';
import validations from 'validations';
import Search from 'components/Search';
import NoSearchMatch from './components/NoSearchMatch';
import Filters from './components/Filters';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import SortDownIcon from 'icons/SortDown.icon';
import SortIcon from 'icons/Sort.icon';
import SortUpIcon from 'icons/SortUp.icon';
import { SelectedFilters } from './components/Filters/Filters.component';
import CircleXmarkIcon from 'icons/CircleXmark.icon';
import RotateRightIcon from 'icons/RotateRight.icon';

import './Table.styles.scss';
import './Table.styles.responsive.scss';

type SearchByOption = {
  label: string;
  value: string;
};

export type TableLabel = {
  label: string;
  key: string;
  isSortable?: boolean;
  sortDir?: SortDirection;
};

export type TableFilter = {
  name: string;
  type: 'radio' | 'select' | 'checkbox' | 'time';
  innerLabel: string;
  selectValue: string;
  selectOptions: Array<SearchByOption>;
};

export type TimeFilterValue = {
  name: string;
  value: string;
};

export type BaseTableItem = {
  id: string;
  rowClassName?: string;
};

type TableProps<T extends BaseTableItem> = {
  title: string;
  totalItems: number;
  totalPages: number;
} & Partial<{
  className: string;
  rowClassName?: string;
  fetching: boolean;
  labels: Array<TableLabel>;
  contentArray: T[];
  buttonText: string;
  saveAllButtonText?: string;
  searchable: boolean;
  keyName?: keyof T;
  limit?: string;
  hasTimeFilter?: boolean;
  timeFilterParams?: TimeFilterValue[];
  tableFilterParams?: TableFilter[];
  searchByOptions?: Array<SearchByOption>;
  searchBy?: string;
  searchPlaceholder?: string;
  headerButtons?: React.ReactNode;
  activeFilters?: string[];
  onRemoveFilter?: (param: any) => void;
  onAddButtonClick: () => void;
  onSaveAllButtonClick: () => void;
  onUploadProducts: () => void;
  onUploadUPCMapping: () => void;
  onRowClick: (el: T) => void;
  onApplyFilters?: (filters: SelectedFilters) => void;
  onResetFilters?: () => void;
  onSearchByChange?: (option: OptionValue<any>) => void;
  onHeaderClick?: (key: string) => void;
  showPagination?: boolean;
}> &
  RowProps<T>;

function Table<T extends BaseTableItem>(props: TableProps<T>) {
  const {
    className,
    rowClassName,
    fetching,
    title,
    contentArray,
    labels,
    saveAllButtonText,
    buttonText,
    totalPages,
    totalItems,
    searchable = false,
    keyName = 'id',
    onAddButtonClick,
    onUploadProducts,
    onUploadUPCMapping,
    onRowClick,
    limit,
    onApplyFilters,
    tableFilterParams,
    onResetFilters,
    searchByOptions,
    searchBy,
    searchPlaceholder,
    onSearchByChange,
    onSaveAllButtonClick,
    onHeaderClick,
    headerButtons,
    activeFilters,
    onRemoveFilter,
    timeFilterParams,
    hasTimeFilter = false,
    showPagination = true,
    ...rest
  } = props;

  const { t } = useTranslation();
  const classes = classNames('hhsa-table ', className);

  const isAtBottom = useScrollBottom([totalItems, limit, fetching]);

  const showFilter =
    showPagination && (totalPages > 1 || totalItems >= minLimitOptionValue);

  const getSortIcon = useCallback(
    (isSortable: boolean, sortDir: SortDirection) => {
      if (!isSortable) return <></>;

      switch (sortDir) {
        case 'ASC':
          return <SortUpIcon />;
        case 'DESC':
          return <SortDownIcon />;
        default:
          return <SortIcon />;
      }
    },
    [],
  );

  return (
    <div className={classes}>
      <div className="hhsa-table__header">
        <div className="hhsa-table__header__title">
          <h1>{title}</h1>
          {!!totalItems && !fetching && <p>{t('total', { totalItems })}</p>}
        </div>
        <div className="hhsa-table__header__actions">
          {(onUploadProducts || onUploadUPCMapping || onAddButtonClick) && (
            <div className="hhsa-table__header__buttons">
              {onAddButtonClick && (
                <Button
                  className="hhsa-table__add"
                  variant="solid"
                  onClick={onAddButtonClick}
                >
                  {buttonText}
                </Button>
              )}
              {onSaveAllButtonClick && (
                <Button
                  className="hhsa-table__save-all"
                  variant="outline"
                  onClick={onSaveAllButtonClick}
                >
                  {saveAllButtonText}
                </Button>
              )}
              {onUploadUPCMapping && (
                <Button
                  className="hhsa-table__upload-file"
                  variant="outline"
                  onClick={onUploadUPCMapping}
                >
                  {t('uploadUpcsMapping')}
                </Button>
              )}
              {onUploadProducts && (
                <Button
                  className="hhsa-table__upload-file"
                  variant="outline"
                  onClick={onUploadProducts}
                >
                  {t('uploadProducts')}
                </Button>
              )}
            </div>
          )}
          <div className="hhsa-table__header__search">
            {searchable && (
              <>
                <Search placeholder={searchPlaceholder} />
                {searchByOptions && (
                  <Select
                    innerLabel="Search by"
                    className="hhsa-dropdown-select"
                    name="search-by"
                    options={searchByOptions}
                    value={searchBy}
                    onChange={onSearchByChange}
                  />
                )}
              </>
            )}
            {tableFilterParams && (
              <Filters
                hasTimeFilter={hasTimeFilter}
                timeFilterParams={timeFilterParams}
                onApplyFilters={onApplyFilters}
                tableFilterParams={tableFilterParams}
                onResetFilters={onResetFilters}
              />
            )}
            {headerButtons}
          </div>
        </div>
      </div>

      {activeFilters?.length > 0 && (
        <section className="hhsa-table__active-filters">
          <span>{t('activeFilters')}:</span>
          {activeFilters?.map((filter) => (
            <Button
              className="hhsa-table__active-filters__filter"
              key={filter}
              onClick={() => onRemoveFilter(filter)}
              icon={<CircleXmarkIcon fill="white" />}
              iconPosition="right"
            >
              {utils.convertCamelCaseToWords(filter)}
            </Button>
          ))}
          <Button
            className="hhsa-table__active-filters__filter"
            onClick={onResetFilters}
            icon={<RotateRightIcon />}
            iconPosition="right"
          >
            {t('resetFilters')}
          </Button>
        </section>
      )}

      {fetching ? (
        <Loader color="#007cb9" inline />
      ) : contentArray?.length === 0 ? (
        searchable ? (
          <NoSearchMatch className="hhsa-table__no-match" />
        ) : (
          <NoSearchMatch
            className="hhsa-table__no-match"
            promptMessage={t('changeFilters')}
          />
        )
      ) : (
        <div className="hhsa-table__main">
          <table>
            <thead>
              <tr className="hhsa-table__labels">
                {labels?.map(({ label, key, isSortable, sortDir }) => (
                  <th
                    key={key}
                    className={classNames(key, { sortable: isSortable })}
                    {...(isSortable
                      ? { onClick: () => onHeaderClick(key) }
                      : {})}
                  >
                    <div>
                      {label}
                      {getSortIcon(isSortable, sortDir)}
                    </div>
                  </th>
                ))}
                {rest?.withActionButtons && <th />}
              </tr>
            </thead>

            <tbody>
              {contentArray?.map((el) => {
                return (
                  <Row
                    className={classNames(el.rowClassName, rowClassName)}
                    key={el[keyName]?.toString()}
                    el={el}
                    {...rest}
                    onRowClick={onRowClick}
                  />
                );
              })}
            </tbody>
          </table>
          {showFilter && (
            <div
              className={classNames('hhsa-table__footer', {
                'hhsa-table__footer--floating': !isAtBottom,
              })}
            >
              <Filter
                className="hhsa-table__pagination"
                name="page"
                defaultValue="1"
                validate={validations.filterValidations.defaultValidatePage}
                component={Pagination}
                totalPages={totalPages}
              />
              <Filter
                className={classNames(
                  'hhsa-dropdown-select',
                  'hhsa-table__limit-select',
                )}
                name="limit"
                component={Select}
                validate={validations.filterValidations.defaultValidateLimit}
                options={limitOptions}
                defaultValue={limit || utils.DEFAULT_LIMIT.toString()}
                valueMap={(o) => o.value}
                innerLabel={t('itemsPerPage')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Table;
