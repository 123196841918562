import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('storeNo'), key: 'storeNo' },
      { label: t('skuNo'), key: 'skuNo' },
      { label: t('RestockList.maxShelfCount'), key: 'maxShelfCount' },
      { label: t('RestockList.minShelfCount'), key: 'minShelfCount' },
      { label: t('RestockList.currentShelfCount'), key: 'currentShelfCount' },
      { label: t('RestockList.quantitySold'), key: 'quantitySold' },
    ],
    [t],
  );
};
