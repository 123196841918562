import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: '', key: 'profileImageUrl' },
      { label: t('name'), key: 'nameLink' },
      { label: t('General.email'), key: 'email' },
      { label: t('General.associateId'), key: 'associateId' },
      { label: t('position'), key: 'position' },
      { label: t('store'), key: 'storeNo' },
    ],
    [t],
  );
};
