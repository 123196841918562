import auth from './auth';
import user from './user';
import associates from './associates';
import stores from './stores';
import admins from './admins';
import features from './features';
import products from './products';
import analytics from './analytics';
import version from './version';
import inventoryLocations from './inventoryLocations';
import restockList from './restockList';
import storeInventory from './storeInventory';
import sales from './sales';
import translations from './translations';

export default {
  auth,
  user,
  associates,
  admins,
  stores,
  features,
  products,
  analytics,
  version,
  inventoryLocations,
  restockList,
  storeInventory,
  sales,
  translations,
};
