import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Form, FormProps } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import FileInputField from 'components/FileInputField';
import AddIcon from 'icons/Add.icon';
import './UploadFileForm.styles.scss';
import FileIcon from 'icons/File.icon';
import formValidators from 'utils/formValidators';
import { toast } from 'react-toastify';
import { ManageStoresFormType } from 'types';
import useTemplateFile from 'hooks/useTemplateFile';

const { required } = formValidators;
const MAX_FILE_SIZE = 64 * 2 ** 20;

type UploadFileFormProps = {
  className?: string;
  onSubmit: (values: {
    attachment: File[];
  }) => SubmissionErrors | Promise<SubmissionErrors> | void;
  inProgress: boolean;
  fileType?: '.xlsx' | '.map';
  formType?: ManageStoresFormType;
  handleCancel?: () => void;
} & FormProps<any>;

const UploadFileForm: React.FC<UploadFileFormProps> = (props) => {
  const {
    className,
    inProgress,
    fileType = '.xlsx',
    formType,
    handleCancel,
    onSubmit,
  } = props;

  const classes = classNames('hhsa-upload-files-form', className);
  const { t } = useTranslation();

  const formApi = useRef<FormApi>();

  const { url: templateHref, message: templateDownloadMessage } =
    useTemplateFile(formType);

  const messages = useMemo(
    () => ({
      upload: t('upload'),
      cancel: t('General.cancel'),
      dragDat: t('dragDat'),
      dragMap: t('dragMap'),
      download: t('download'),
    }),
    [t],
  );

  const removeFile = useCallback(
    (index: number) => {
      const { values } = formApi.current.getState();

      const { attachment } = values;
      const arrayOfAttachments = Array.from(attachment);

      formApi.current.batch(() => {
        formApi.current.change(
          'attachment',
          arrayOfAttachments.filter((_, i) => index !== i),
        );
      });
    },
    [formApi],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'document/*': [fileType] },
    multiple: false,
    noClick: true,
    noKeyboard: true,
    // image limit is 10MB
    maxSize: MAX_FILE_SIZE, // 2^20 is 1MB
    maxFiles: 1,
    onDropAccepted: (files) => {
      const filteredFiles: File[] = [];
      const promises: Promise<any>[] = [];

      files.forEach(async (file) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        promises.push(
          new Promise(
            (resolve, reject) =>
              (reader.onload = async (e) => {
                const { size } = file;
                if (size > MAX_FILE_SIZE) {
                  toast.error(
                    t('maxFileSize', {
                      maxFileSize: (MAX_FILE_SIZE / 2 ** 20).toFixed(0),
                    }),
                  );
                  reject();
                } else {
                  Object.assign(file, {
                    preview: e.target?.result,
                  });
                  filteredFiles.push(file);
                  resolve(file);
                }
              }),
          ),
        );

        await Promise.all(promises);
        formApi.current.batch(() => {
          formApi.current.change('attachment', files);
        });
      });
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        switch (file.errors[0].code) {
          case 'file-too-large':
            toast.error(t('file_larger_than'));
            return;
          case 'file-invalid-type':
            toast.error(t('notification_file_type'));
            return;
        }
      });
    },
  });

  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, values, form } = formRenderProps;

        formApi.current = form;
        const files: File[] = values?.attachment;
        const submitDisabled = invalid || inProgress;
        const hasFiles = files?.length > 0;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            {inProgress ? (
              <Loader color="#007cb9" inline />
            ) : (
              <div className="hhsa-upload-files-form__drag">
                <span className="hhsa-upload-files-form__file-input__label">
                  {t('import')}
                </span>
                <div className="hhsa-upload-files-form__template-download">
                  <p>{templateDownloadMessage}</p>
                  <a
                    className="hhsa-upload-files-form__template-download__link"
                    href={templateHref}
                  >
                    {messages.download}
                  </a>
                </div>
                {hasFiles ? (
                  <ul className="hhsa-upload-files-form__files">
                    {files?.map(({ name }, index) => (
                      <li
                        key={name + index}
                        className="hhsa-upload-files-form__files__file"
                      >
                        <FileIcon />
                        <span className="hhsa-upload-files-form__files__file__name">
                          {name}
                        </span>

                        <Button
                          type="button"
                          variant="link"
                          styleType="secondary"
                          onClick={() => removeFile(index)}
                        >
                          {t('remove')}
                        </Button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <FileInputField
                    inputProps={getInputProps()}
                    name="attachment"
                    className="hhsa-upload-files-form__file-input"
                    maxFiles={1}
                    validate={required(t('required'))}
                    required
                    accept={fileType}
                    trigger={
                      <div
                        className={classNames(
                          'hhsa-upload-files-form__file-input__trigger',
                        )}
                        {...getRootProps()}
                      >
                        <AddIcon />
                        <span style={{ fontWeight: 400, color: '#a2a4a6' }}>
                          {fileType === '.xlsx'
                            ? messages.dragDat
                            : messages.dragMap}
                        </span>
                      </div>
                    }
                  />
                )}
              </div>
            )}
            <div className="hhsa-manage-stores-form__actions">
              <Button
                type="button"
                onClick={handleCancel}
                variant="link"
                className="hhsa-manage-stores-form__actions__link"
              >
                <span>{messages.cancel} </span>
              </Button>

              <Button
                type="submit"
                disabled={submitDisabled}
                variant="solid"
                className="hhsa-manage-stores-form__actions__submit"
              >
                {messages.upload}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default UploadFileForm;
