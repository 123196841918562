import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import Modal from 'components/Modal';
import ProductDetails from '../../../ProductCatalog/components/ProductDetails';
import Table from 'router/subrouters/Dashboard/components/Table';
import { useParams } from 'react-router-dom';
import usePaginatedItems from '../../../hooks/usePaginatedItems';
import useQueryParams from 'hooks/useQueryParams';
import {
  ChangePriceBody,
  ProductCatalogPriceForm,
  StaplesProduct,
} from 'models/Products';
import { parseVersion } from '../../../ProductCatalog/utils';
import { ProductSearchBy, productSearchByOptions } from 'types';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import ChangePriceForm from '../../../ProductCatalog/components/ChangePriceForm';
import { Button } from 'ncoded-component-library';
import useLabels from './hooks/useLabels';
import { useQueryClient } from '@tanstack/react-query';
import InfoIcon from 'icons/Info-2.icon';
import { Tooltip } from 'react-tooltip';

import './StoreProducts.styles.scss';

export type ProductProps = {
  id: string;
  imageUrl: string;
};

const StoreProducts: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();

  const queryClient = useQueryClient();

  const modalRef = useRef<ModalRef>();
  const priceModalRef = useRef<ModalRef>();

  const [productDetails, setProductDetails] = useState<ProductProps>(null);
  const [selectedProduct, setSelectedProduct] = useState<ChangePriceBody>();

  const { storeNo } = useParams();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: ProductSearchBy;
  }>({ searchBy: 'name' });

  const { items, isFetching, totalPages, totalItems } = usePaginatedItems({
    queryKey: 'store-products',
    makeRequest: api.products.getProductsByStore,
    searchParam: searchBy,
    params: {
      limit,
      page,
      [searchBy]: !!search && search.length >= 2 ? search : undefined,
      storeNo,
    },
  });

  const renderPrice = useCallback(
    (
      price: number,
      chainPrice: number,
      differentStorePrice: boolean,
      isPromoPrice: boolean,
      tooltipId: string,
    ) => {
      const tooltipContent =
        price !== null && chainPrice !== null
          ? t(isPromoPrice ? 'differentPromoPrice' : 'differentRegularPrice', {
              price: chainPrice,
            })
          : t('noChainPrice');

      if (price && differentStorePrice && price !== chainPrice) {
        return (
          <span className="hhsa-store-products__regular-price">
            {price}
            <InfoIcon
              className="hhsa-store-products__regular-price__warning-icon"
              data-tooltip-id={tooltipId}
              data-tooltip-content={tooltipContent}
            />
            <Tooltip id={tooltipId} />
          </span>
        );
      }

      return price;
    },
    [t],
  );

  const renderContentArray = useCallback(
    (data: StaplesProduct[]) => {
      return data?.map(
        ({
          productCatalog: {
            imageUrls = [],
            name,
            regularPrice: chainRegularPrice,
            promoPrice: chainPromoPrice,
          } = {},
          skuNo,
          version,
          id,
          expirationDate,
          differentStorePrice,
          regularPrice,
          promoPrice,
        }) => ({
          imageUrl: imageUrls[0],
          nameLink: (
            <Button
              variant="link"
              className="store-link"
              onClick={() => {
                setProductDetails({ id: id, imageUrl: imageUrls[0] });
                modalRef.current.open();
              }}
            >
              {name}
            </Button>
          ),

          skuNo,
          id,
          lastUpdate: parseVersion(version),
          storeRegularPrice: renderPrice(
            regularPrice,
            chainRegularPrice,
            differentStorePrice,
            false,
            'regularPriceDiff',
          ),
          regularPrice,
          storePromoPrice: renderPrice(
            promoPrice,
            chainPromoPrice,
            differentStorePrice,
            true,
            'promoPriceDiff',
          ),
          promoPrice,
          expirationDate,
          differentStorePrice,
        }),
      );
    },
    [renderPrice],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  useEffect(() => {
    if (!productDetails?.id) {
      modalRef.current.close();
    }
  }, [productDetails?.id]);

  useEffect(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(productSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (values: ProductCatalogPriceForm) => {
      const { skuNo, expirationDate, version } = selectedProduct;
      const { promoPrice, regularPrice } = values;
      try {
        await api.products.updateStoreProductPrices({
          skuNo,
          storeNo: +storeNo,
          expirationDate,
          version,
          promoPrice: +promoPrice,
          regularPrice: +regularPrice,
        });
        queryClient.invalidateQueries({
          queryKey: ['store-products'],
        });

        setSelectedProduct(null);
      } catch (err) {
        console.error(err);
      } finally {
        priceModalRef.current.close();
      }
    },
    [queryClient, selectedProduct, storeNo],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        type="no-action"
        ref={modalRef}
        modalName="store-product"
        onClose={() => setProductDetails(null)}
        onX={() => {
          setProductDetails(null);
          modalRef.current.close();
        }}
        title={t('productDetails')}
      >
        <ProductDetails
          id={productDetails?.id}
          img={productDetails?.imageUrl}
        />
      </Modal>
      <Modal
        focusableElements="div"
        type="no-action"
        ref={priceModalRef}
        modalName="change-store-price"
        onClose={() => setSelectedProduct(null)}
        onX={() => {
          setSelectedProduct(null);
          priceModalRef.current.close();
        }}
        title={t('editStoreSpecificPrice')}
      >
        <ChangePriceForm
          onSubmit={(values) => onSubmit(values)}
          storeNo={storeNo}
          prices={{
            regularPrice: selectedProduct?.regularPrice,
            promoPrice: selectedProduct?.promoPrice,
          }}
          handleCancel={() => {
            setSelectedProduct(null);
            priceModalRef.current.close();
          }}
        />
      </Modal>
      <Table
        totalItems={totalItems}
        fetching={isFetching}
        title={t('storeProducts', { storeNo: storeNo })}
        labels={labels}
        elementProps={labels?.map(({ key }) => key)}
        cellComponentMap={{ imageUrl: 'ProductImage' }}
        searchable
        contentArray={renderContentArray(items)}
        totalPages={totalPages}
        limit={limit}
        withActionButtons
        onEdit={(values) => {
          setSelectedProduct(values);
          priceModalRef.current.open();
        }}
        searchBy={searchBy}
        searchByOptions={searchByOptions}
        onSearchByChange={handleSearchByChange}
        searchPlaceholder={t('searchProductPlaceholder')}
      />
    </>
  );
};

export default StoreProducts;
