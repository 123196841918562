import React, { useCallback, useMemo } from 'react';
import Table from '../../components/Table';
import useLabels from './hooks/useLabels';
import usePaginatedItems from '../hooks/usePaginatedItems';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import { StoreInventoryProduct } from 'api/storeInventory';
import { useTranslation } from 'react-i18next';
import {
  storeProductInventoryByOptions,
  StoreProductInventorySearchBy,
} from 'types';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';

const StoreInventory = () => {
  const labels = useLabels();

  const { t } = useTranslation();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: StoreProductInventorySearchBy;
  }>({ searchBy: 'skuNo' });

  const { items, isFetching, totalItems, totalPages } = usePaginatedItems({
    queryKey: 'store-inventory',
    makeRequest: api.storeInventory.getStoreProductInventory,
    searchParam: searchBy,
    params: {
      limit,
      page,
      [searchBy]: !!search && search?.length >= 2 ? search : undefined,
    },
  });

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const renderContentArray = useCallback(
    (data: StoreInventoryProduct[]) =>
      data?.map(({ storeProduct, ...rest }) => ({
        skuNo: storeProduct.skuNo,
        storeNo: storeProduct.storeNo,
        ...rest,
      })),
    [],
  );

  const searchByOptions = useMemo(
    () =>
      Object.values(storeProductInventoryByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );
  return (
    <Table
      title={t('StoreProductInventory.title')}
      labels={labels}
      fetching={isFetching}
      contentArray={renderContentArray(items)}
      totalItems={totalItems}
      totalPages={totalPages}
      elementProps={labels.map(({ key }) => key)}
      searchable
      searchBy={searchBy}
      searchByOptions={searchByOptions}
      searchPlaceholder={t('StoreProductInventory.searchPlaceholder')}
      onSearchByChange={handleSearchByChange}
    />
  );
};

export default StoreInventory;
