import React from 'react';
import { Navigate } from 'react-router-dom';
import ManageUsers from './pages/ManageUsers';
import { ComplexRoute } from 'router/components/Routes/Routes';
import ManageStores from './pages/ManageStores/pages/ManageStores';
import ManageAdmins from './pages/ManageAdmins';
import FeatureFlags from './pages/FeatureFlags';
import ProductCatalog from './pages/ProductCatalog';
import ActivityLog from './pages/ActivityLog';
import Associate from './pages/Associate';
// import VersionSelect from './pages/VersionSelect';
import Checksums from './pages/Checksums/Checksums.page';
import InventoryLocationsPage from './pages/InventoryLocations';
import StoreInventory from './pages/StoreInventory';
import storeRoutes from './pages/ManageStores/pages/ManageStores/routes';
import StoreTabs from 'components/StoreTabs';
import TranslationsPage from './pages/Translations';
import TranslationTabs from 'components/TranslationTabs/TranslationTabs.component';

export default [
  {
    path: 'manage-associates',
    element: ManageUsers,
  },
  {
    path: 'manage-stores',
    element: ManageStores,
  },
  {
    path: 'manage-stores/:storeNo',
    element: StoreTabs,
    routes: storeRoutes,
  },
  {
    path: 'manage-admins',
    element: ManageAdmins,
  },
  {
    path: 'checksums',
    element: Checksums,
  },
  {
    path: 'feature-flags',
    element: FeatureFlags,
  },
  {
    path: 'product-catalog',
    element: ProductCatalog,
  },
  {
    path: 'activity-log',
    element: ActivityLog,
  },
  {
    path: 'associate/:id',
    element: Associate,
  },
  {
    path: 'inventory-locations',
    element: InventoryLocationsPage,
  },
  {
    path: 'checksums',
    element: Checksums,
  },
  {
    path: 'store-product-inventory',
    element: StoreInventory,
  },
  {
    path: 'translations-admin',
    element: <TranslationTabs type="admin" />,
    routes: [
      {
        path: '',
        element: <Navigate to="en" replace />,
      },
      {
        path: ':language',
        element: TranslationsPage,
      },
    ],
  },
  {
    path: 'translations-mobile',
    element: <TranslationTabs type="mobile" />,
    routes: [
      {
        path: '',
        element: <Navigate to="en" replace />,
      },
      {
        path: ':language',
        element: TranslationsPage,
      },
    ],
  },
  // {
  //   path: 'version-select',
  //   element: VersionSelect,
  // },
  {
    index: true,
    element: <Navigate to="manage-associates" replace />,
  },
] as Array<ComplexRoute>;
