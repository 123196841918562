import { useMemo } from 'react';
import api from 'api';
import utils from 'utils';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type ChartDataProps = {
  userId: number;
};

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const initialDataset = {
  'Move Inventory': 0,
  'Report Invalid Price': 0,
  'Remove Invalid Price': 0,
  'Product Scanned': 0,
};

type PieDataset = typeof initialDataset;

export default (props: ChartDataProps) => {
  const { userId } = props;

  const { t } = useTranslation();

  const { data: pieDataset } = useQuery<PieDataset>({
    queryKey: ['activityCount', userId],
    queryFn: async () => {
      const data = await api.analytics.getActivityCount(userId);

      const newDataset = data.reduce((acc, { count, type }) => {
        acc[type] = count;
        return acc;
      }, {} as Partial<PieDataset>);

      return { ...initialDataset, ...newDataset };
    },
    onError: (err) => utils.handleResponseError(err),
  });

  const pieData = useMemo(() => {
    if (!pieDataset) return null;

    return {
      labels: Object.keys(pieDataset)?.map((label) =>
        t(`${label?.replace(/\s/g, '')}`),
      ),
      datasets: [
        {
          label: 'Number of activities',
          data: Object.values(pieDataset),
          backgroundColor: ['#c4bfdb', '#fdf6d8', '#f19143', '#1f93cc'],
          borderColor: ['#4d428d', '#7a631b', '#5e3f1f', '#243d76'],
          borderWidth: 1,
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        },
      ],
    };
  }, [t, pieDataset]);

  return { pieData, chartOptions };
};
