import React, { useCallback } from 'react';
import Table from '../../components/Table';
import useLabels from './hooks/useLabels';
import { useTranslation } from 'react-i18next';
import { RestockListItem } from 'models/RestockListItem';
import { useParams } from 'react-router-dom';
import api from 'api';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

const RestockListPage = () => {
  const labels = useLabels();

  const { storeNo } = useParams();

  const { t } = useTranslation();

  const fetchRestockList = useCallback(async () => {
    try {
      const { data } = await api.restockList.getRestockList({
        storeNo: +storeNo,
      });
      return data;
    } catch (error) {
      toast.error(t('General.error'));
      throw error;
    }
  }, [storeNo, t]);

  const { data, isFetching } = useQuery(
    ['restockList', { storeNo: +storeNo }],
    fetchRestockList,
    {
      enabled: !!storeNo,
    },
  );

  const renderContentArray = useCallback(
    (data: RestockListItem[]) =>
      data?.map(({ storeNo, skuNo, ...rest }) => ({
        id: `${skuNo}`,
        storeNo,
        skuNo,
        ...rest,
      })),
    [],
  );

  return (
    <Table
      className="manage-users__table"
      title={t('RestockList.title')}
      labels={labels}
      elementProps={labels.map(({ key }) => key)}
      contentArray={renderContentArray(data)}
      totalItems={data?.length}
      totalPages={1}
      fetching={isFetching}
    />
  );
};

export default RestockListPage;
