import React from 'react';
import useProduct from '../../hooks/useProduct';
import { Loader } from 'ncoded-component-library';
// import MoreInfoLink from '../MoreInfoLink';
import dateService from 'services/dateService';
import ProductImage from 'components/ProductImage';

import './ProductDetails.styles.scss';

type ProductDetailsProps = {
  id: string;
  img?: string;
};

const ProductDetails: React.FC<ProductDetailsProps> = (props) => {
  const { id, img } = props;

  const { isLoading, product: displayingProduct } = useProduct(id);
  const { regularPrice, promoPrice, expirationDate, skuNo, productCatalog } =
    displayingProduct || {};

  const name = productCatalog?.name || '';

  const showCrossedOut =
    !!promoPrice && !!regularPrice && regularPrice !== promoPrice;

  const displayPrice = promoPrice ?? regularPrice;

  return isLoading ? (
    <Loader color="#007cb9" inline />
  ) : (
    <div className="hhsa-product-modal">
      <h4>{name}</h4>
      <span>SKU:{skuNo}</span>
      <div className="hhsa-product-modal__main">
        <ProductImage img={img} alt="product-modal-img" />
        <div className="hhsa-product-modal__main__details">
          {showCrossedOut && (
            <span className="regular-price">{`$ ${regularPrice?.toFixed(
              2,
            )}`}</span>
          )}
          {displayPrice && (
            <span className="promo-price">{`$ ${displayPrice.toFixed(
              2,
            )}`}</span>
          )}

          {!!expirationDate && (
            <span>
              {dateService.formatExpirationDateString(expirationDate)}
            </span>
          )}
        </div>
      </div>
      {/* <MoreInfoLink skuNo={skuNo} /> */}
      {/* to be added when there is a new link for aisle optix instead of staples */}
    </div>
  );
};

export default ProductDetails;
