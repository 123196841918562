import classNames from 'classnames';
import InputField from 'components/InputField';
import { SubmissionErrors } from 'final-form';
import { ProductCatalogPriceForm } from 'models/Products';
import { Button } from 'ncoded-component-library';
import React, { useMemo } from 'react';
import { Form, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import './ChangePriceForm.styles.scss';

type ChangePriceFormProps = {
  prices: ProductCatalogPriceForm;
  storeNo?: string;
  className?: string;
  onSubmit: (
    values: ProductCatalogPriceForm,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  handleCancel?: () => void;
  inProgress?: boolean;
} & FormProps<ProductCatalogPriceForm>;

const ChangePriceForm = (props: ChangePriceFormProps) => {
  const { prices, storeNo, className, onSubmit, handleCancel } = props;

  const { t } = useTranslation();

  const classes = classNames('hhsa-change-price-form', className);

  const messages = useMemo(
    () => ({
      regularPrice: t('regularPrice'),
      promoPrice: t('discountedPrice'),
      updatePrices: t('updatePrices'),
      cancel: t('General.cancel'),
      infoMessage: t('priceChangeInfo', { storeNo }),
    }),
    [storeNo, t],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={prices}
      render={(formRenderProps) => {
        const { handleSubmit, dirty } = formRenderProps;
        return (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              type="number"
              name="regularPrice"
              label={messages.regularPrice}
              placeholder={messages.regularPrice}
              required
            />
            <InputField
              type="number"
              name="promoPrice"
              label={messages.promoPrice}
              placeholder={messages.promoPrice}
            />
            {storeNo && (
              <span className="hhsa-change-price-form__info-message">
                {messages.infoMessage}
              </span>
            )}

            <div className="hhsa-change-price-form__actions">
              <Button
                type="button"
                onClick={handleCancel}
                variant="outline"
                className="hhsa-change-price-form__actions__link"
              >
                <span>{messages.cancel} </span>
              </Button>

              <Button
                disabled={!dirty}
                type="submit"
                variant="solid"
                className="hhsa-change-price-form__actions__submit"
              >
                {messages.updatePrices}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default ChangePriceForm;
