import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLabel } from 'router/subrouters/Dashboard/components/Table/Table.component';

export default (): Array<TableLabel> => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('activityType'), key: 'type' },
      { label: t('General.associateName'), key: 'name' },
      { label: t('position'), key: 'position' },
      { label: t('skuNo'), key: 'skuNo' },
      { label: t('storeNo'), key: 'storeNo' },
      { label: t('updatedAt'), key: 'updatedAt', isSortable: true },
    ],
    [t],
  );
};
