import React, { useCallback } from 'react';
import { activityColors } from 'types';
import { Activity } from 'models/Activity';
import { Link } from 'react-router-dom';
import utils from 'utils';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const renderContentArray = useCallback(
    (data: Activity[]) =>
      data?.map(
        ({
          typeId,
          type,
          id,
          updatedAt,
          userId,
          skuNo,
          user: { name, storeNo, position, profileImageUrl },
        }) => ({
          name: (
            <Link className="activity-link" to={`/associate/${userId}`}>
              <Avatar
                className="activity-link__avatar"
                withInfo={false}
                img={profileImageUrl}
              />
              {name}
            </Link>
          ),
          storeNo: (
            <Link className="activity-link" to={`/manage-stores/${storeNo}`}>
              {storeNo}
            </Link>
          ),
          id,
          position,
          skuNo,
          type: (
            <div className={`activity-type ${activityColors[type]}`}>
              {t(`${type?.replace(/\s/g, '')}`)}
            </div>
          ),
          updatedAt: utils.formatISODate(updatedAt),
        }),
      ),
    [t],
  );

  return renderContentArray;
};
