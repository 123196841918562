import Tabs from 'components/Tabs';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const StoreTabs = () => {
  const { storeNo } = useParams();

  const tabs = useMemo(
    () => [
      { path: `/manage-stores/${storeNo}/products`, label: 'Products' },
      { path: `/manage-stores/${storeNo}/sales`, label: 'Sales' },
      {
        path: `/manage-stores/${storeNo}/restock-list`,
        label: 'RestockList.title',
      },
    ],
    [storeNo],
  );
  return <Tabs tabs={tabs} />;
};

export default StoreTabs;
