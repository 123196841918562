import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('storeNo'), key: 'storeNo' },
      { label: t('InventoryLocations.binNo'), key: 'binNo' },
      { label: t('InventoryLocations.locationName'), key: 'locationName' },
      {
        label: t('InventoryLocations.sellingStatus'),
        key: 'locationSellingStatus',
      },
      { label: t('InventoryLocations.locationStatus'), key: 'locationStatus' },
      { label: t('InventoryLocations.type'), key: 'type' },
    ],
    [t],
  );
};
