import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import api from 'api';

const supportedLanguages = ['EN', 'FR'];

const fetchTranslations = async (
  lang: 'EN' | 'FR',
  mobile: boolean,
): Promise<Resource> => {
  try {
    const cachedTranslations = localStorage.getItem(
      `translations_${mobile ? 'Mobile' : 'Admin'}_${lang}`,
    );
    if (cachedTranslations) {
      return JSON.parse(cachedTranslations);
    }
    const { translation } = await api.translations.getTranslationFile(
      lang,
      mobile,
      null,
    );

    localStorage.setItem(
      `translations_${mobile ? 'Mobile' : 'Admin'}_${lang}`,
      JSON.stringify(translation),
    );
    return translation;
  } catch (error) {
    console.error('Error fetching translations:', error);
    return {};
  }
};

export const initializeTranslations = async () => {
  await fetchTranslations('EN', true);
  await fetchTranslations('FR', true);
  const resourcesEnAdmin = await fetchTranslations('EN', false);
  const resourcesFrAdmin = await fetchTranslations('FR', false);

  const resources: Resource = {
    EN: {
      translation: resourcesEnAdmin,
    },
    FR: {
      translation: resourcesFrAdmin,
    },
  };

  i18n.use({
    type: 'languageDetector',
    async: true,
    detect: (
      callback: (arg0: string | { language: string } | string[]) => void,
    ) => {
      const language = localStorage.getItem('language');
      if (!language) {
        // eslint-disable-next-line no-console
        console.log('No language is set, choosing English as fallback');
      } else {
        const finalLanguage = supportedLanguages?.includes(language)
          ? [language]
          : ['EN'];
        callback(finalLanguage);
        return;
      }
      callback(supportedLanguages);
    },

    cacheUserLanguage: (language: string) => {
      localStorage.setItem('language', language);
    },
  });

  i18n.use(initReactI18next).init({
    resources,
    debug: false,
    fallbackLng: 'EN',
    saveMissing: true,
  });
};
