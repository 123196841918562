import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import InputField from 'components/InputField';
import { SubmissionErrors } from 'final-form';
import { LoginReqBody } from 'models/AuthBody';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import formValidators from 'utils/formValidators';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { useNavigate } from 'react-router-dom';

import './LoginForm.styles.scss';

type LoginFormProps = {
  className?: string;
  onSubmit: (
    values: LoginReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  inProgress: boolean;
} & FormProps<LoginReqBody>;

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { className, initialValues, inProgress, onSubmit } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const classes = classNames('hhsa-login-form', className);

  const messages = useMemo(
    () => ({
      associateId: t('General.associateId'),
      password: t('General.password'),
      login: t('General.signin'),
      forgotPassword: `${t('General.forgotPassword')}?`,
      environment: t('Environment'),
    }),
    [t],
  );

  const validation = useMemo(
    () => ({
      password: formValidators.composeValidators(
        formValidators.required(t('required')),
      ),
      associateId: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.associateIDValidation(
          t('General.associateIdRequirements'),
        ),
      ),
      environment: formValidators.required(t('General.wrongLogin')),
    }),
    [t],
  );

  const forgotPassword = useCallback(() => {
    navigate('../forgot-password');
  }, [navigate]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid || inProgress;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              name="associateId"
              label={messages.associateId}
              placeholder="ID"
              validate={validation.associateId}
            />

            <PasswordField
              name="password"
              label={messages.password}
              placeholder={messages.password}
              validate={validation.password}
              hidePasswordStrength
            />
            <span
              onClick={forgotPassword}
              className="hhsa-login-form__forgot-password"
            >
              {t('General.forgotPassword')}?
            </span>
            <Button
              type="submit"
              disabled={submitDisabled}
              variant="solid"
              className="hhsa-login-form__submit"
            >
              {messages.login}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default LoginForm;
