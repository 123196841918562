import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: '', key: 'imageUrl' },
      { label: t('name'), key: 'nameLink' },
      { label: 'SKU', key: 'skuNo' },
      { label: 'UPC', key: 'upc' },
      { label: t('Last update'), key: 'lastUpdate' },
      { label: t('regularPrice'), key: 'storeRegularPrice' },
      { label: t('discountedPrice'), key: 'storePromoPrice' },
      { label: t('promoEnd'), key: 'expirationDate' },
    ],
    [t],
  );
};
