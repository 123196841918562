import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Form, FormProps } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import formValidators, { MAX_STORE_NO_CHARS } from 'utils/formValidators';
import { ManageStoresFormBody, Store, storeStatusType } from 'models/Stores';
import SelectField from 'components/SelectField';
import useStore from './hooks/useStore';

import './ManageStoresForm.styles.scss';

const { required } = formValidators;

type ManageStoresFormProps = {
  className?: string;
  storeId?: number;
  onSubmit: (
    values: ManageStoresFormBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  inProgress: boolean;
  handleCancel?: () => void;
} & FormProps<ManageStoresFormBody>;

const ManageStoresForm: React.FC<ManageStoresFormProps> = (props) => {
  const { storeId, className, inProgress, handleCancel, onSubmit } = props;
  const { store, loading } = useStore(storeId);

  const classes = classNames('hhsa-manage-stores-form', className);
  const { t } = useTranslation();

  const messages = useMemo(
    () => ({
      storeNumber: t('storeNumber'),
      districtNo: t('districtNo'),
      storeName: t('storeName'),
      city: t('city'),
      province: t('province'),
      postalCode: t('postalCode'),
      addStore: t('addStore'),
      editStore: t('save'),
      phoneNo: t('phoneNo'),
      faxNo: t('faxNo'),
      cancel: t('General.cancel'),
      address: t('address'),
      streetAddress: t('streetAddress'),
      status: t('status'),
    }),
    [t],
  );

  const positions = useMemo(
    () =>
      Object.values(storeStatusType).map((status) => ({
        label: t(`${status}`),
        value: status,
      })),
    [t],
  );

  const validation = useMemo(
    () => ({
      storeNo: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_STORE_NO_CHARS }),
          MAX_STORE_NO_CHARS,
        ),
      ),
    }),
    [t],
  );

  const initialValuesForStore: Partial<Store> = useMemo(
    () => ({
      name: store?.name,
      storeNo: store?.storeNo,
      districtNo: store?.districtNo,
      address: store?.address,
      city: store?.city,
      province: store?.province,
      postalCode: store?.postalCode,
      phoneNo: store?.phoneNo,
      faxNo: store?.faxNo,
      status: store?.status,
    }),
    [
      store?.address,
      store?.city,
      store?.districtNo,
      store?.faxNo,
      store?.name,
      store?.phoneNo,
      store?.postalCode,
      store?.province,
      store?.status,
      store?.storeNo,
    ],
  );

  const disabled = useMemo(() => !!storeId, [storeId]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={store ? initialValuesForStore : {}}
      render={(formRenderProps) => {
        const { handleSubmit, invalid, values, dirty } = formRenderProps;

        const submitDisabled = invalid || inProgress || !dirty;

        const { status } = values;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            {inProgress || loading ? (
              <Loader color="#007cb9" inline />
            ) : (
              <div className={'hhsa-manage-stores-form__fields'}>
                <InputField
                  type="text"
                  name="name"
                  label={messages.storeName}
                  required
                  placeholder={messages.storeName}
                  validate={required(t('required'))}
                />
                <InputField
                  type="text"
                  name="storeNo"
                  label={messages.storeNumber}
                  required
                  placeholder={messages.storeNumber}
                  validate={validation.storeNo}
                />

                <InputField
                  type="number"
                  name="districtNo"
                  disabled={disabled}
                  label={messages.districtNo}
                  required
                  placeholder={messages.districtNo}
                  validate={required(t('required'))}
                />

                <InputField
                  type="text"
                  name="address"
                  label={messages.address}
                  disabled={disabled}
                  required
                  placeholder={messages.streetAddress}
                  validate={required(t('required'))}
                />
                <InputField
                  type="text"
                  name="city"
                  label={messages.city}
                  disabled={disabled}
                  required
                  placeholder={messages.city}
                  validate={required(t('required'))}
                />
                <InputField
                  type="text"
                  name="province"
                  label={messages.province}
                  disabled={disabled}
                  required
                  placeholder="NN"
                  validate={required(t('required'))}
                />
                <InputField
                  type="text"
                  name="postalCode"
                  label={messages.postalCode}
                  disabled={disabled}
                  required
                  placeholder="NNN-NNN"
                  validate={required(t('required'))}
                />
                <InputField
                  type="number"
                  name="phoneNo"
                  label={messages.phoneNo}
                  required
                  placeholder="***-***-****"
                  validate={required(t('required'))}
                />
                <InputField
                  type="number"
                  name="faxNo"
                  label={messages.faxNo}
                  required
                  placeholder="***-***-****"
                  validate={required(t('required'))}
                />
                <SelectField
                  name="status"
                  options={positions}
                  label={messages.status}
                  validate={required(t('required'))}
                  innerLabel={!status && t('selectAnOption')}
                />
              </div>
            )}
            <div className="hhsa-manage-stores-form__actions">
              <Button
                type="button"
                onClick={handleCancel}
                variant="outline"
                className="hhsa-manage-stores-form__actions__link"
              >
                <span>{messages.cancel} </span>
              </Button>

              <Button
                type="submit"
                disabled={submitDisabled}
                variant="solid"
                className="hhsa-manage-stores-form__actions__submit"
              >
                {storeId ? messages.editStore : messages.addStore}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default ManageStoresForm;
