import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('skuNo'), key: 'skuNo' },
      { label: t('storeNo'), key: 'storeNo' },
      { label: t('StoreProductInventory.onHand'), key: 'onHand' },
      {
        label: t('StoreProductInventory.availableToSell'),
        key: 'availableToSell',
      },
      {
        label: t('StoreProductInventory.currentShelfCount'),
        key: 'currentShelfCount',
      },
      {
        label: t('StoreProductInventory.totalStoreCapacity'),
        key: 'totalStoreCapacity',
      },
    ],
    [t],
  );
};
