import httpClient from '../httpClient';
import { ManageStoresFormBody, Store } from 'models/Stores';
import {
  Pagination,
  RequestParams,
  Searchable,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';

export type StoreName = {
  storeNo: number;
  name: string;
};

class SearchStore implements Searchable {
  keys() {
    return ['storeNo', 'name'] as const;
  }
}

export type StaplesStore = SearchStore & Store;

const storesPath = (routePath: TemplateStringsArray | string = '') =>
  `stores/${routePath}`;

const getStores = async (
  params: RequestParams<StaplesStore>,
): Promise<Pagination<StaplesStore>> => {
  return httpClient
    .get<Pagination<StaplesStore>>(storesPath``, { params })
    .then((response) => response.data);
};

const getStoreNames = () =>
  httpClient.get<StoreName[]>('stores/names').then(({ data }) => data);

const getStore = (id: string) => {
  return httpClient.get<Store>(storesPath(id));
};

const addStore = (store: ManageStoresFormBody) => {
  if (store.storeNo && store.districtNo) {
    store.storeNo = +store.storeNo;
    store.districtNo = +store.districtNo;
  }
  return httpClient.post(storesPath``, store);
};

const updateStore = (store: Partial<ManageStoresFormBody>, id: string) => {
  if (store.storeNo) {
    store.storeNo = +store.storeNo;
  }
  return httpClient.patch<Store>(storesPath(id), store);
};

const uploadProducts = (formData: FormData) => {
  return httpClient.post('product-catalog', formData);
};

const uploadUPCMapping = (formData: FormData) => {
  return httpClient.post('product-catalog/upcs', formData);
};

const deleteStore = (id: string) => {
  return httpClient.delete(storesPath(id));
};

export default {
  getStores,
  getStoreNames,
  getStore,
  addStore,
  updateStore,
  uploadProducts,
  uploadUPCMapping,
  deleteStore,
};
