import Tabs from 'components/Tabs';
import React, { useMemo } from 'react';

interface TranslationTabsProps {
  type: 'admin' | 'mobile';
}

const TranslationTabs: React.FC<TranslationTabsProps> = ({ type }) => {
  const tabs = useMemo(
    () => [
      { path: `/translations-${type}/en`, label: 'english' },
      { path: `/translations-${type}/fr`, label: 'french' },
    ],
    [type],
  );

  return <Tabs tabs={tabs} />;
};

export default TranslationTabs;
