import { ChangePriceBody, StaplesProduct } from 'models/Products';
import httpClient from '../httpClient';
import {
  Pagination,
  RequestParams,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';

const getProducts = async (params: RequestParams<StaplesProduct>) => {
  return httpClient
    .get<Pagination<StaplesProduct>>('admin-product-catalog', {
      params: {
        ...params,
      },
    })
    .then(({ data }) => data);
};

const updateChainProductPrices = (data: ChangePriceBody) =>
  httpClient.patch('admin-product-catalog/price-update', data);

const updateStoreProductPrices = (data: ChangePriceBody) =>
  httpClient.patch('admin-products/price-update', data);

const getProductsByStore = async (params: RequestParams<StaplesProduct>) => {
  const storeNo = params?.storeNo;
  return httpClient
    .get<Pagination<StaplesProduct>>(`admin-products/${storeNo}/all`, {
      params: {
        ...params,
      },
    })
    .then(
      ({ data }) =>
        ({
          ...data,
          // TODO Remove this workaround when API fixes endpoint
          items: data.items.map((i) => ({
            ...i,
            name: i.name || i.productCatalog.name,
          })),
        } as typeof data),
    );
};

const getProductById = (id: string) =>
  httpClient.get<StaplesProduct>(`admin-products/${id}`);

export default {
  getProducts,
  getProductsByStore,
  getProductById,
  updateChainProductPrices,
  updateStoreProductPrices,
};
