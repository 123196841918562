import httpClient from '../httpClient';
import { User } from 'models/User';
import { ManageAdminsFormBody } from 'models/Admins';
import {
  Pagination,
  RequestParams,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';
import { StaplesUser } from 'api/associates';

type StoreChecksum = {
  store_no: number;
  cnt: number;
  priceSum: number;
};

const adminsPath = (routePath: TemplateStringsArray | string) =>
  `admins/${routePath}`;

const getAdmins = async (
  params: RequestParams<StaplesUser>,
): Promise<Pagination<StaplesUser>> => {
  return httpClient
    .get<Pagination<StaplesUser>>(adminsPath``, { params })
    .then((response) => response.data);
};

const getAdmin = (id: number) => {
  return httpClient.get<User>(adminsPath(`${id}`));
};

const addAdmin = (admin: ManageAdminsFormBody) => {
  return httpClient.post(adminsPath``, admin);
};

const updateAdmin = (admin: Partial<ManageAdminsFormBody>, id: number) => {
  return httpClient.put<User>(adminsPath(`${id}`), {
    position: admin?.position,
    password: admin?.temporaryPassword,
  });
};

const uploadAdminFile = (file: FormData) =>
  httpClient.post<User>(adminsPath(`profile-image`), file);

const getChecksums = (storeNo: number) =>
  httpClient
    .get<StoreChecksum>(`/admin-products/${storeNo}/checksum`)
    .then(({ data }) => data);

const resetPassword = (password: string) =>
  httpClient.patch<User>(adminsPath``, { password });

const changePassword = (password: string) =>
  httpClient
    .post<User>('users/reset-password', { password })
    .then(({ data }) => data);

export default {
  getAdmins,
  getChecksums,
  getAdmin,
  addAdmin,
  updateAdmin,
  uploadAdminFile,
  resetPassword,
  changePassword,
};
