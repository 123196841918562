import httpClient from 'api/httpClient';
import { StoreSale } from 'models/Sale';
import { RequestParams } from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';

const salesPath = (routePath: TemplateStringsArray | string = '') =>
  `sales-data/${routePath}`;

const getStoreSales = async (
  params?: RequestParams<StoreSale>,
): Promise<any> => {
  const storeNo = params?.storeNo;
  return httpClient
    .get(salesPath(`${storeNo}/all`), { params })
    .then((response) => response.data);
};

export default { getStoreSales };
