import { useCallback, useEffect, useState } from 'react';
import api from 'api';
import { InventoryLocations } from 'models/Inventory';

const useInventoryLocation = (
  binNo?: number,
): {
  inventoryLocation?: InventoryLocations;
  loading?: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [inventoryLocation, setInventoryLocation] =
    useState<InventoryLocations>(null);

  const getInventoryLocation = useCallback(async () => {
    try {
      setLoading(true);
      if (binNo) {
        const { data } = await api.inventoryLocations.getInventoryLocation(
          binNo,
        );
        setInventoryLocation(data);
      } else return;
    } catch (err) {
      console.error();
    } finally {
      setLoading(false);
    }
  }, [binNo]);

  useEffect(() => {
    getInventoryLocation();
  }, [getInventoryLocation]);

  return {
    inventoryLocation,
    loading,
  };
};

export default useInventoryLocation;
