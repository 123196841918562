import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Table from '../../components/Table';
import useLabels from './hooks/useLabels';
import Modal, { ModalRef } from 'components/Modal';
import ManageTranslationForm from './components/ManageTranslationForm';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import utils from 'utils';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import { TranslationItem } from 'models/Translation';
import useTranslationHelpers from './hooks/useTranslationHelpers';

import './Translations.styles.scss';

const TranslationsPage = () => {
  const modalRef = useRef<ModalRef>();
  const [currentLanguage, setCurrentLanguage] = useState<'en' | 'fr'>('en');
  const [selectedItem, setSelectedItem] = useState<TranslationItem>();
  const [accumulatedChanges, setAccumulatedChanges] = useState<{
    [key: string]: string;
  }>(null);

  const { t } = useTranslation();
  const location = useLocation();
  const labels = useLabels();

  const {
    params: { search },
  } = useQueryParams();

  const adminOrMobile = useMemo(
    () => (location.pathname.includes('admin') ? 'Admin' : 'Mobile'),
    [location.pathname],
  );

  const {
    flattenTranslations,
    unflattenTranslations,
    currentTranslation,
    setCurrentTranslation,
  } = useTranslationHelpers(adminOrMobile, currentLanguage);

  const renderContentArray = useCallback(() => {
    const flattenedTranslations = flattenTranslations({
      ...currentTranslation,
      ...accumulatedChanges,
    });

    const translations = Object.entries(flattenedTranslations).map(
      ([key, value], index) => ({
        id: index.toString(),
        key,
        translation: value.toString(),
        rowClassName:
          accumulatedChanges && key in accumulatedChanges && 'edited-row',
      }),
    );

    if (search) {
      return translations.filter(
        (item) =>
          item.key.toLowerCase().includes(search.toLowerCase()) ||
          item.translation.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return translations;
  }, [accumulatedChanges, currentTranslation, flattenTranslations, search]);

  const onSubmit = useCallback((values: { key: string; value: string }) => {
    setAccumulatedChanges((prevChanges) => ({
      ...prevChanges,
      [values.key]: values.value,
    }));
    modalRef.current.close();
  }, []);

  const onSaveAll = useCallback(async () => {
    try {
      const fullTranslation = unflattenTranslations({
        ...currentTranslation,
        ...accumulatedChanges,
      });

      const formData = new FormData();
      const blob = new Blob([JSON.stringify(fullTranslation)], {
        type: 'application/json',
      });
      formData.append('file', blob, `translation-${currentLanguage}.json`);

      const { translation } = await api.translations.addTranslationFile(
        currentLanguage.toUpperCase(),
        formData,
        adminOrMobile === 'Mobile',
      );

      setCurrentTranslation({ ...currentTranslation, ...accumulatedChanges });

      setAccumulatedChanges(null);

      localStorage.setItem(
        `translations_${adminOrMobile}_${currentLanguage.toUpperCase()}`,
        JSON.stringify(translation),
      );

      modalRef.current.close();
      setSelectedItem(null);

      toast.success(t('successEditingTranslation'));
    } catch (error) {
      const errorMsg = utils.handleResponseError(error);
      toast.error(errorMsg);
    }
  }, [
    accumulatedChanges,
    adminOrMobile,
    currentLanguage,
    currentTranslation,
    setCurrentTranslation,
    t,
    unflattenTranslations,
  ]);

  useEffect(() => {
    const initialLang = location.pathname.includes('fr') ? 'fr' : 'en';
    setCurrentLanguage(initialLang);
  }, [location.pathname]);

  return (
    <>
      <Table
        title={t(
          location.pathname.includes('admin')
            ? 'Translations.titleAdmin'
            : 'Translations.titleMobile',
        )}
        labels={labels}
        className="translations-table"
        contentArray={renderContentArray()}
        totalItems={renderContentArray.length}
        totalPages={1}
        withActionButtons
        buttonText={t('addTranslation')}
        saveAllButtonText={t('SaveAll')}
        onSaveAllButtonClick={
          accumulatedChanges ? () => onSaveAll() : utils.noop()
        }
        onAddButtonClick={() => {
          modalRef.current.open();
        }}
        onEdit={(el) => {
          setSelectedItem(el);
          modalRef.current.open();
        }}
        elementProps={labels.map(({ key }) => key)}
        showPagination={false}
        searchable
        searchPlaceholder={t('Translations.searchPlaceholder')}
      />
      <Modal
        focusableElements="div"
        className="translations__modal"
        type="no-action"
        ref={modalRef}
        modalName="edit-translation"
        onClose={() => setSelectedItem(null)}
        onX={() => {
          modalRef.current.close();
          setSelectedItem(null);
        }}
        title={selectedItem ? t('editTranslation') : t('addTranslation')}
      >
        <ManageTranslationForm
          selectedItem={selectedItem}
          handleCancel={() => {
            modalRef.current.close();
          }}
          language={currentLanguage.toUpperCase()}
          onSubmit={onSubmit}
        />
      </Modal>
    </>
  );
};

export default TranslationsPage;
