import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import aisleOptixLogo from '../../../../../../public/aisle-optix-logo.png';
import { useTranslation } from 'react-i18next';
import CodeConfirmation from './components/CodeConfirmation';
import { useAuth } from '../../Auth.router';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './CheckYourEmail.styles.scss';

const CheckYourEmail = () => {
  const [wrongCode, setWrongCode] = useState(false);
  const location = useLocation();
  const { verifyCode } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const classes = classNames('hhsa-check-your-email');

  const onFinalCell = useCallback(
    async (code: number) => {
      setWrongCode(false);
      try {
        await verifyCode(code, location.state.email);
        navigate('../../reset-password', { state: { forgotPassword: true } });
      } catch (error) {
        toast.error(t('General.error'));
        setWrongCode(true);
      }
    },
    [location.state.email, navigate, t, verifyCode],
  );

  return (
    <div className={classes}>
      <img
        src={aisleOptixLogo}
        alt="logo"
        className="hhsa-check-your-email__logo"
      />
      <div className="hhsa-forgot-password__message">
        {t('checkYourEmailMessage')}
      </div>
      <CodeConfirmation wrongCode={wrongCode} onFinalCell={onFinalCell} />
    </div>
  );
};

export default CheckYourEmail;
