import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useLabels from './hooks/useLabels';
import api from 'api';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import Modal from 'components/Modal';
import usePaginatedItems from '../hooks/usePaginatedItems';
import utils from 'utils';
import useQueryParams from 'hooks/useQueryParams';
import { useQueryClient } from '@tanstack/react-query';
import ManageAdminsForm from './components/ManageAdminsForm';
import { ManageAdminsFormBody } from 'models/Admins';

import '../ManageUsers/ManageUsers.styles.scss';

const ManageAdmins: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();
  const queryClient = useQueryClient();

  const {
    params: { page, limit },
  } = useQueryParams<{ page: string; limit: string }>();

  const { items, isRefreshing, isFetching, totalItems, totalPages } =
    usePaginatedItems({
      queryKey: 'admins',
      makeRequest: api.admins.getAdmins,
      refetchOnMount: 'always',
      params: {
        limit,
        page,
      },
    });

  const modalRef = useRef<ModalRef>();

  const addAdmin = useCallback(
    async (values: ManageAdminsFormBody) => {
      try {
        values.position = 'Admin';
        await api.admins.addAdmin(values);
        toast.success(t('successAddingAdmin'));
        modalRef.current.close();
        queryClient.invalidateQueries({
          queryKey: ['admins'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const onSubmit = useCallback(
    async (values: ManageAdminsFormBody) => {
      values.associateId = +values.associateId;
      addAdmin(values);
    },
    [addAdmin],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        className="manage-users__modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-admin"
        onX={() => {
          modalRef.current.close();
        }}
        title={t('newAssociate')}
      >
        <ManageAdminsForm
          inProgress={isRefreshing}
          onSubmit={(values) => onSubmit(values)}
          handleCancel={() => {
            modalRef.current.close();
          }}
        />
      </Modal>
      <Table
        fetching={isFetching}
        title={t('admins')}
        labels={labels}
        elementProps={labels.map(({ key }) => key)}
        contentArray={items}
        buttonText={t('addAdmin')}
        onAddButtonClick={() => modalRef.current.open()}
        totalItems={totalItems}
        totalPages={totalPages}
        limit={limit}
      />
    </>
  );
};

export default ManageAdmins;
