import classNames from 'classnames';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import { InventoryLocations } from 'models/Inventory';
import { Button, Loader } from 'ncoded-component-library';
import React, { useMemo } from 'react';
import { Form, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import formValidators from 'utils/formValidators';
import { SubmissionErrors } from 'final-form';
import usePaginatedItems from '../../../hooks/usePaginatedItems';
import api from 'api';
import useInventoryLocation from './hooks/useInventoryLocation';

import './InventoryLocationsForm.styles.scss';
import {
  locationStatusOptions,
  sellingStatusOptions,
  typeOptions,
} from 'types';

const { required } = formValidators;

type InventoryLocationsFormProps = {
  loading?: boolean;
  className?: string;
  binNo?: number;
  onSubmit?: (
    values: InventoryLocations,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  handleCancel?: () => void;
} & FormProps<InventoryLocations>;

const InventoryLocationsForm = (props: InventoryLocationsFormProps) => {
  const { binNo, className, onSubmit, handleCancel } = props;
  const { inventoryLocation, loading } = useInventoryLocation(binNo);

  const { t } = useTranslation();
  const classes = classNames('hhsa-manage-inventory-locations-form', className);

  const { items: stores } = usePaginatedItems({
    queryKey: 'stores',
    makeRequest: api.stores.getStores,
    params: {
      limit: '250',
    },
  });

  const messages = useMemo(
    () => ({
      storeNumber: t('storeNumber'),
      binNo: t('InventoryLocations.binNo'),
      locationName: t('InventoryLocations.locationName'),
      locationSellingStatus: t('InventoryLocations.locationSellingStatus'),
      locationStatus: t('InventoryLocations.locationStatus'),
      type: t('InventoryLocations.type'),
      maxShelfCapacity: t('InventoryLocations.maxShelfCapacity'),
      cancel: t('General.cancel'),
      editLocation: t('InventoryLocations.edit'),
      addLocation: t('InventoryLocations.add'),
    }),
    [t],
  );

  const sellingStatusOptionsMapped = useMemo(
    () =>
      Object.values(sellingStatusOptions).map(({ value }) => ({
        label: t(`${value}`),
        value,
      })),
    [t],
  );

  const locationStatusOptionsMapped = useMemo(
    () =>
      Object.values(locationStatusOptions).map(({ value }) => ({
        label: t(`${value}`),
        value,
      })),
    [t],
  );

  const typeOptionsMapped = useMemo(
    () =>
      Object.values(typeOptions).map(({ value }) => ({
        label: t(`${value}`),
        value,
      })),

    [t],
  );

  const storesOptions = useMemo(
    () =>
      (stores &&
        Object.values(stores).map((store) => ({
          label: store.name,
          value: store.storeNo,
        }))) ||
      [],
    [stores],
  );

  const initialValuesForAssociate: Partial<InventoryLocations> = useMemo(
    () => ({
      storeNo: inventoryLocation?.storeNo,
      binNo: inventoryLocation?.binNo,
      locationName: inventoryLocation?.locationName,
      locationSellingStatus: inventoryLocation?.locationSellingStatus,
      locationStatus: inventoryLocation?.locationStatus,
      type: inventoryLocation?.type,
      maxShelfCapacity: inventoryLocation?.maxShelfCapacity,
    }),
    [
      inventoryLocation?.binNo,
      inventoryLocation?.locationName,
      inventoryLocation?.locationSellingStatus,
      inventoryLocation?.locationStatus,
      inventoryLocation?.maxShelfCapacity,
      inventoryLocation?.storeNo,
      inventoryLocation?.type,
    ],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={inventoryLocation ? initialValuesForAssociate : {}}
      render={(formRenderProps) => {
        const { handleSubmit, values } = formRenderProps;

        const { locationSellingStatus, type, locationStatus, storeNo } = values;
        return loading ? (
          <Loader color="#007cb9" />
        ) : (
          <form className={classes} onSubmit={handleSubmit}>
            <SelectField
              name="storeNo"
              options={storesOptions}
              label={messages.storeNumber}
              validate={required(t('required'))}
              innerLabel={!storeNo && t('selectAnOption')}
            />
            <InputField
              type="number"
              name="binNo"
              label={messages.binNo}
              required
              validate={required(t('required'))}
              placeholder={messages.binNo}
            />
            <InputField
              type="text"
              name="locationName"
              label={messages.locationName}
              required
              validate={required(t('required'))}
              placeholder={messages.locationName}
            />
            <SelectField
              name="locationSellingStatus"
              options={sellingStatusOptionsMapped}
              innerLabel={!locationSellingStatus && t('selectAnOption')}
              label={messages.locationSellingStatus}
              validate={required(t('required'))}
            />
            <SelectField
              name="locationStatus"
              options={locationStatusOptionsMapped}
              innerLabel={!locationStatus && t('selectAnOption')}
              label={messages.locationStatus}
              validate={required(t('required'))}
            />
            <InputField
              type="number"
              name="maxShelfCapacity"
              label={messages.maxShelfCapacity}
              required
              validate={required(t('required'))}
              placeholder={messages.maxShelfCapacity}
            />
            <SelectField
              name="type"
              options={typeOptionsMapped}
              innerLabel={!type && t('selectAnOption')}
              label={messages.type}
              validate={required(t('required'))}
            />
            <div className="hhsa-manage-inventory-locations-form__actions">
              <Button
                type="button"
                onClick={handleCancel}
                variant="outline"
                className="hhsa-manage-inventory-locations-form__link"
              >
                <span>{messages.cancel} </span>
              </Button>

              <Button
                // disabled={submitDisabled}
                type="submit"
                variant="solid"
                className="hhsa-manage-inventory-locations-form__actions__submit"
              >
                {inventoryLocation
                  ? messages.editLocation
                  : messages.addLocation}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default InventoryLocationsForm;
