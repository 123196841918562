import React, { useMemo } from 'react';
import classNames from 'classnames';
import InputField from 'components/InputField';
import { SubmissionErrors } from 'final-form';
import { Button } from 'ncoded-component-library';
import { Form, FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import './ForgotPasswordForm.styles.scss';
import formValidators from 'utils/formValidators';
import { ForgotPasswordReqBody } from 'models/AuthBody';
const { getEmailValidators } = formValidators;

type ForgotPasswordFormProps = {
  className?: string;
  onSubmit: (
    values: ForgotPasswordReqBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues?: Partial<ForgotPasswordReqBody>;
  inProgress: boolean;
} & FormProps<ForgotPasswordReqBody>;

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { className, initialValues, onSubmit, inProgress } = props;

  const { t } = useTranslation();

  const classes = classNames('hhsa-forgot-password-form', className);

  const messages = useMemo(
    () => ({
      email: t('General.email'),
      emailPlaceholder: t('General.emailPlaceholder'),
      send: t('General.send'),
    }),
    [t],
  );

  const emailValidators = useMemo(() => getEmailValidators(t), [t]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid || inProgress;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              type="email"
              name="email"
              label={messages.email}
              placeholder={messages.emailPlaceholder}
              validate={emailValidators}
            />

            <Button
              type="submit"
              disabled={submitDisabled}
              className="hhsa-forgot-password-form__submit"
              variant="solid"
            >
              {messages.send}
            </Button>
          </form>
        );
      }}
    />
  );
};

export default ForgotPasswordForm;
