import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const labels = useMemo(
    () => [
      { label: '', key: 'profileImageUrl' },
      { label: t('name'), key: 'name' },
      { label: t('General.email'), key: 'email' },
      { label: t('General.associateId'), key: 'associateId' },
      { label: t('position'), key: 'position' },
    ],
    [t],
  );
  return labels;
};
