import { useCallback, useRef, useState } from 'react';

const useCodeConfirmation = (onFinalCell?: (code: number) => void) => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [maskedOtp, setMaskedOtp] = useState(['', '', '', '']);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const maskOtp = useCallback((index: number) => {
    setMaskedOtp((prev) => {
      const updatedMaskedOtp = [...prev];
      updatedMaskedOtp[index] = '•';
      return updatedMaskedOtp;
    });
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const value = e.target.value;
      if (isNaN(+value) || !value) return;

      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      const updatedMaskedOtp = [...maskedOtp];
      updatedMaskedOtp[index] = value;
      setMaskedOtp(updatedMaskedOtp);

      setTimeout(() => maskOtp(index), 500);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else {
        const code = updatedOtp.join('');
        onFinalCell?.(Number(code));
      }
    },
    [otp, maskedOtp, maskOtp, onFinalCell],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e.key === 'Backspace') {
        const updatedOtp = [...otp];
        if (!updatedOtp[index] && index > 0) {
          updatedOtp[index - 1] = '';
          setOtp(updatedOtp);

          const updatedMaskedOtp = [...maskedOtp];
          updatedMaskedOtp[index - 1] = '';
          setMaskedOtp(updatedMaskedOtp);

          inputRefs.current[index - 1]?.focus();
        } else {
          updatedOtp[index] = '';
          setOtp(updatedOtp);

          const updatedMaskedOtp = [...maskedOtp];
          updatedMaskedOtp[index] = '';
          setMaskedOtp(updatedMaskedOtp);
        }
      }
    },
    [otp, maskedOtp],
  );

  return { handleChange, handleKeyDown, maskedOtp, inputRefs, otp };
};

export default useCodeConfirmation;
