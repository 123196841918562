import { useCallback, useEffect, useState } from 'react';

const useTranslationHelpers = (
  adminOrMobile: 'Admin' | 'Mobile',
  currentLanguage: 'en' | 'fr',
) => {
  const [currentTranslation, setCurrentTranslation] = useState<any>();

  const flattenTranslations = useCallback((obj: any, prefix = '') => {
    return Object.keys(obj).reduce(
      (acc: Record<string, string>, key: string) => {
        const fullKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(acc, flattenTranslations(obj[key], fullKey));
        } else {
          acc[fullKey] = obj[key].toString();
        }
        return acc;
      },
      {},
    );
  }, []);

  const unflattenTranslations = useCallback(
    (flatObject: Record<string, string>): Record<string, any> => {
      const result: Record<string, any> = {};

      Object.keys(flatObject).forEach((flatKey) => {
        const keys = flatKey.split('.');
        keys.reduce((acc, key, index) => {
          if (index === keys.length - 1) {
            acc[key] = flatObject[flatKey];
          } else {
            acc[key] = acc[key] || {};
          }
          return acc[key];
        }, result);
      });

      return result;
    },
    [],
  );

  useEffect(() => {
    setCurrentTranslation(
      JSON.parse(
        localStorage.getItem(
          `translations_${adminOrMobile}_${currentLanguage.toUpperCase()}`,
        ),
      ),
    );
  }, [adminOrMobile, currentLanguage]);

  return {
    flattenTranslations,
    unflattenTranslations,
    currentTranslation,
    setCurrentTranslation,
  };
};

export default useTranslationHelpers;
