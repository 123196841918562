import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('key'), key: 'key' },
      { label: t('Translations.translation'), key: 'translation' },
    ],
    [t],
  );
};
