import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from 'ncoded-component-library';
import useQueryParams from 'hooks/useQueryParams';
import { SortDirection } from 'types';
import api from 'api';
import { Activity } from 'models/Activity';
import utils from 'utils';
import Modal, { ModalRef } from 'components/Modal';
import FileCsvIcon from 'icons/FileCsv.icon';
import { CSVLink } from 'react-csv';
import useLabels from '../../hooks/useLabels';
import { useInfinitePaginatedItems } from '../../../hooks/useInfinitePaginatedItems';
import { useTranslation } from 'react-i18next';
import Progress from 'components/ProgressBar';

import './ActivityExport.styles.scss';

type ActivityExportProps = {
  isFetching?: boolean;
};

const ActivityExport: React.FC<ActivityExportProps> = (props) => {
  const { isFetching } = props;

  const { t } = useTranslation();

  const [shouldFetchAll, setShouldFetchAll] = useState(false);
  const [enableFetching, setEnableFetching] = useState(false);

  const {
    params: {
      page,
      types,
      userId,
      position,
      storeNo,
      startDate,
      endDate,
      sortBy,
      sortDir,
    },
  } = useQueryParams<{
    page: string;
    limit: string;
    types: string;
    userId: string;
    position: string;
    storeNo: string;
    startDate: string;
    endDate: string;
    sortBy: string;
    sortDir: SortDirection;
  }>();

  const {
    items,
    hasNextPage,
    progress,
    fetchNextPage,
    isFetching: isFetchingExport,
  } = useInfinitePaginatedItems({
    queryKey: 'activityExport',
    makeRequest: api.analytics.getActivity,
    enabled: enableFetching,
    params: {
      limit: '250',
      page,
    },
    additionalParams: {
      startDate,
      endDate,
      types: types?.split(', '),
      position,
      storeNo,
      userId,
      sortBy,
      sortDir,
    },
  });

  const prepareCsvData = useCallback((data: Activity[]) => {
    return data?.map(
      ({ type, updatedAt, skuNo, user: { name, storeNo, position } }) => ({
        name,
        storeNo,
        position,
        skuNo,
        type,
        updatedAt: utils.formatISODate(updatedAt),
      }),
    );
  }, []);

  const csvData = useMemo(() => {
    if (hasNextPage || isFetchingExport) return [];

    return prepareCsvData(items);
  }, [hasNextPage, isFetchingExport, prepareCsvData, items]);

  const modalRef = useRef<ModalRef>();

  const labels = useLabels();

  useEffect(() => {
    if (!isFetchingExport) {
      if (shouldFetchAll && hasNextPage) {
        fetchNextPage();
      } else if (!hasNextPage) {
        setShouldFetchAll(false);
      }
    }
  }, [fetchNextPage, hasNextPage, shouldFetchAll, items, isFetchingExport]);

  return (
    <div className="activity-export">
      <Button
        className="filters__button"
        onClick={() => {
          setShouldFetchAll(true);
          setEnableFetching(true);
          modalRef.current.open();
        }}
        icon={<FileCsvIcon />}
        disabled={isFetching}
      >
        {t('export')}
      </Button>

      <Modal
        focusableElements="div"
        className="activity-export-modal"
        type="no-action"
        ref={modalRef}
        modalName="manage-stores"
        onClose={() => {
          setShouldFetchAll(false);
        }}
        onX={() => {
          setShouldFetchAll(false);
          modalRef.current.close();
        }}
        title={t('exportCsv')}
      >
        <div className="activity-export-modal__progress">
          {hasNextPage || isFetchingExport ? (
            <>
              <div>
                {t('preparingCsvMessage', {
                  progress: Math.floor(progress * 100),
                })}
              </div>
              <Progress value={progress} />
            </>
          ) : (
            <>
              <div>{t('readyForDownload')}</div>
              <CSVLink
                className="activity-export-modal__link"
                filename="activity-log.csv"
                data={csvData}
                headers={labels}
              >
                {t('downloadCsv')}
              </CSVLink>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ActivityExport;
