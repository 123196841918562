import { ResetPasswordFormValues } from 'models/AuthBody';
import { User } from 'models/User';
import { createContext } from 'react';

type UserContextProps = {
  currentUser: Partial<User> | null;
  loading: boolean;
  setCurrentUser: React.Dispatch<React.SetStateAction<Partial<User>>>;
  logout: () => void;
  updateAdminInformation: (personalInfo: Partial<User>) => void;
  resetPassword: (values: ResetPasswordFormValues) => Promise<void>;
  uploadAdminFile: (files: File[]) => Promise<User>;
  changePassword: (values: ResetPasswordFormValues) => Promise<void>;
  getUser: () => Promise<void>;
};

export default createContext<Partial<UserContextProps>>({});
