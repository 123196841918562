import httpClient from 'api/httpClient';
import publicHttpClient from 'api/publicHttpClient';

const getTranslationFile = (
  language: string,
  mobile: boolean,
  lastFetched: string,
) =>
  publicHttpClient
    .post<{ translation: any }>(
      'translations/fetch',
      { lastFetched },
      {
        params: { language, mobile },
      },
    )
    .then((data) => data.data);

const addTranslationFile = (
  language: string,
  file: FormData,
  mobile: boolean,
) =>
  httpClient
    .post<{ translation: any }>('translations', file, {
      params: { language, mobile },
    })
    .then((data) => data.data);

export default {
  getTranslationFile,
  addTranslationFile,
};
