import publicHttpClient from 'api/publicHttpClient';
import { AuthBody, LoginReqBody } from '../../models/AuthBody';

export const authPath = (routePath: TemplateStringsArray) =>
  `admins/${routePath}`;

function login(userData: LoginReqBody) {
  return publicHttpClient.post<AuthBody>(authPath`auth/login`, userData);
}

const refreshToken = (refreshToken: string) => {
  return publicHttpClient.post(authPath`auth/refresh-token`, { refreshToken });
};

function forgotPassword(email: string) {
  return publicHttpClient.post('users/auth/reset-password', { email });
}
function resetPassword(password: string, token: string) {
  return publicHttpClient.post(authPath`reset-password`, {
    token,
    password,
  });
}

function verifyCode(code: number, email: string) {
  return publicHttpClient.post<AuthBody>('users/auth/confirm-code', {
    code,
    email,
  });
}

export default {
  login,
  refreshToken,
  forgotPassword,
  resetPassword,
  verifyCode,
};
