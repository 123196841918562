import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Form, FormProps } from 'react-final-form';
import { SubmissionErrors } from 'final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import PasswordField from 'components/PasswordField';
import { Button, Loader } from 'ncoded-component-library';
import formValidators, {
  MAX_ASSOCIATE_ID_CHARS,
  MAX_STORE_NO_CHARS,
} from 'utils/formValidators';
import { ManageAdminsFormBody } from 'models/Admins';
import { User } from 'models/User';
import useAssociate from '../../../ManageUsers/components/ManageUsersForm/hooks/useAssociate';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';

import '../../../ManageUsers/ManageUsers.styles.scss';

const { required } = formValidators;

type ManageUsersFormProps = {
  className?: string;
  onSubmit: (
    values: ManageAdminsFormBody,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  handleCancel?: () => void;
  associateId?: number;
  inProgress: boolean;
} & FormProps<ManageAdminsFormBody>;

const ManageAdminsForm: React.FC<ManageUsersFormProps> = (props) => {
  const { className, associateId, inProgress, handleCancel, onSubmit } = props;
  const { associate, loading } = useAssociate(associateId, true);

  const classes = classNames('hhsa-manage-users-form', className);
  const { t } = useTranslation();

  const { currentUser: { id = '' } = {} } = useContext(CurrentUserContext);

  const editMyself = associateId === +id;

  const messages = useMemo(
    () => ({
      adminName: t('name'),
      associateId: t('General.associateId'),
      position: t('position'),
      tempPass: t('General.tempPassword'),
      password: t('General.password'),
      confirmNewPassword: t('General.confirmNewPassword'),
      cancel: t('General.cancel'),
      addAdmin: t('addAdmin'),
      updateAdmin: t('updateAdmin'),
      passwordMismatch: t('General.passwordMismatch'),
      id: t('id'),
      moveInventoryStyle: t('moveInventoryStyle'),
      changePass: t('General.changePassword'),
      email: t('General.email'),
    }),
    [t],
  );

  const passwordValidators = useMemo(
    () => formValidators.getPasswordValidators(t),
    [t],
  );

  const passwordMatchValidator = useCallback(
    (values: ManageAdminsFormBody) => {
      const errors: Partial<ManageAdminsFormBody> = {};

      if (values.temporaryPassword !== values.confirmPassword) {
        errors.confirmPassword = messages.passwordMismatch;
      }

      return errors;
    },
    [messages.passwordMismatch],
  );

  const validation = useMemo(
    () => ({
      associateId: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_ASSOCIATE_ID_CHARS }),
          MAX_ASSOCIATE_ID_CHARS,
        ),
        formValidators.noSpaces(t('noSpaces')),
      ),

      storeNo: formValidators.composeValidators(
        formValidators.required(t('required')),
        formValidators.maxChars(
          t('maxChars', { chars: MAX_STORE_NO_CHARS }),
          MAX_STORE_NO_CHARS,
        ),
      ),
    }),
    [t],
  );

  const initialValuesForAssociate: Partial<User> = useMemo(
    () => ({
      name: associate?.name,
      associateId: associate?.associateId,
      position: associate?.position,
      storeNo: associate?.store?.storeNo,
      inventoryScreenType: associate?.inventoryScreenType,
      email: associate?.email,
    }),
    [
      associate?.name,
      associate?.associateId,
      associate?.position,
      associate?.store?.storeNo,
      associate?.inventoryScreenType,
      associate?.email,
    ],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={associate ? initialValuesForAssociate : {}}
      validate={passwordMatchValidator}
      render={(formRenderProps) => {
        const { handleSubmit, dirty, values, invalid } = formRenderProps;

        const submitDisabled = inProgress || !dirty || invalid;

        const { temporaryPassword, confirmPassword } = values;

        return loading ? (
          <Loader color="#007cb9" />
        ) : (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              type="text"
              name="name"
              label={messages.adminName}
              disabled={!!associateId}
              required
              validate={required(t('required'))}
              placeholder={messages.adminName}
            />
            <InputField
              type="text"
              name="email"
              label={messages.email}
              disabled={!!associateId}
              required
              validate={required(t('required'))}
              placeholder={messages.email}
            />
            <InputField
              type="number"
              disabled={!!associateId}
              name="associateId"
              label={messages.associateId}
              required
              validate={validation.associateId}
              placeholder={messages.id}
            />

            <>
              <PasswordField
                name="temporaryPassword"
                label={editMyself ? messages.password : messages.tempPass}
                validate={!!temporaryPassword && passwordValidators}
                required={!associateId}
                hidePasswordStrength
                placeholder="********"
              />
              <PasswordField
                name="confirmPassword"
                label={messages.confirmNewPassword}
                validate={!!confirmPassword && passwordValidators}
                required={!associateId}
                hidePasswordStrength
                placeholder="********"
              />
            </>

            <div className="hhsa-manage-users-form__actions">
              <Button
                type="button"
                onClick={handleCancel}
                variant="link"
                className="hhsa-manage-users-form__actions__link"
              >
                <span>{messages.cancel} </span>
              </Button>

              <Button
                disabled={submitDisabled}
                type="submit"
                variant="solid"
                className="hhsa-manage-users-form__actions__submit"
              >
                {associateId ? messages.updateAdmin : messages.addAdmin}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default ManageAdminsForm;
