import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ncoded-component-library';
import ForgotPasswordForm from './components';
import aisleOptixLogo from '../../../../../../public/aisle-optix-logo.png';
import { ForgotPasswordReqBody } from 'models/AuthBody';
import { useAuth } from '../../Auth.router';

import './ForgotPassword.styles.scss';
import './ForgotPassword.styles.responsive.scss';

type ForgotPasswordProps = {
  className?: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const { forgotPassword, isForgotPasswordInProgress } = useAuth();

  const classes = classNames('hhsa-forgot-password', className);

  const navigate = useNavigate();

  const messages = useMemo(
    () => ({
      title: t('General.forgotPassword'),
      backToLogin: t('General.backToLogin'),
    }),
    [t],
  );

  const handleForgotPassword = useCallback(
    async (values: ForgotPasswordReqBody) => {
      try {
        await forgotPassword(values);
        navigate('../check-your-email', { state: { email: values.email } });
      } catch (error) {
        // Error handled in parent
      }
    },
    [forgotPassword, navigate],
  );
  const handleBack = useCallback(() => {
    navigate('/auth/login', { replace: true });
  }, [navigate]);

  return (
    <div className={classes}>
      <img
        className="hhsa-forgot-password__logo"
        src={aisleOptixLogo}
        alt="logo"
      />
      <div className="hhsa-forgot-password__message">
        {t('forgotPasswordMessage')}
      </div>
      <ForgotPasswordForm
        onSubmit={handleForgotPassword}
        inProgress={isForgotPasswordInProgress}
      />
      <Button
        type="button"
        className="hhsa-forgot-password__back-button"
        variant="link"
        onClick={handleBack}
      >
        <span>{messages.backToLogin}</span>
      </Button>
    </div>
  );
};

export default ForgotPassword;
