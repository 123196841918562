import httpClient from 'api/httpClient';
import { InventoryLocations } from 'models/Inventory';
import {
  Pagination,
  RequestParams,
  Searchable,
} from 'router/subrouters/Dashboard/pages/hooks/usePaginatedItems';

class SearchInventories implements Searchable {
  keys() {
    return ['storeNo', 'locationStatus', 'locationSellingStatus'] as const;
  }
}

export type InventoryType = SearchInventories &
  InventoryLocations & { id: string };

const inventoriesPath = (routePath: TemplateStringsArray | string) =>
  `inventory-locations/${routePath}`;

const getInventoryLocations = async (
  params: RequestParams<InventoryType>,
): Promise<Pagination<InventoryType>> => {
  return httpClient
    .get<Pagination<InventoryType>>(inventoriesPath``, { params })
    .then((response) => response.data);
};

const getInventoryLocation = (binNo: number) =>
  httpClient.get(inventoriesPath(String(binNo)));

const addInventoryLocation = (values: InventoryLocations) =>
  httpClient.post(inventoriesPath``, values);

const editInventoryLocation = (
  values: Partial<InventoryLocations>,
  binNo: number,
) => httpClient.patch(inventoriesPath(String(binNo)), values);

const deleteLocation = (binNo: string) =>
  httpClient.delete(inventoriesPath(binNo));

export default {
  getInventoryLocations,
  getInventoryLocation,
  addInventoryLocation,
  editInventoryLocation,
  deleteLocation,
};
