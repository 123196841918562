import api from 'api';
import { Store } from 'models/Stores';
import { useCallback, useEffect, useState } from 'react';

const useStore = (id: number): { store?: Store; loading?: boolean } => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState<Store>(null);

  const getStore = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const { data } = await api.stores.getStore(`${id}`);
        setStore(data);
      } else return;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getStore();
  }, [getStore]);

  return { store, loading };
};

export default useStore;
